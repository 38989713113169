import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppConfig } from './appconfig.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  baseUrl= AppConfig.settings.apiUrl; //"http://localhost:5000/";
  constructor(private http: HttpClient) { }


  public getGroups(){
    return this.http.get(this.baseUrl+"api/schedulec/groups" );
  }

  public getFilterData(groupNumber){
    let params = new HttpParams();
    params = params.append('groupNumber', groupNumber);
    return this.http.get(this.baseUrl+"api/schedulec/filters", {params:params} );
  }

  public getScheduleCReport(groupNumber, calendarYear, isoReceiptDate){
    let params = new HttpParams();
    params = params.append('groupNumber', groupNumber);
    params = params.append('calendarYear', calendarYear);
    params = params.append('isoReceiptDate', isoReceiptDate);
    return this.http.get(this.baseUrl+"api/schedulec/report", {params:params} );
  }

  public getScheduleAGroups(){
    return this.http.get(this.baseUrl+"api/scheduleaadhoc/groups" );
  }

  public getScheduleAFilter(groupNumber){
    let params = new HttpParams();
    params = params.append('groupNumber', groupNumber);
    return this.http.get(this.baseUrl+"api/scheduleaadhoc/filters", {params:params} );
  }

  public getScheduleAReport(groupNumber, calendarYear, isoReceiptDate){
    let params = new HttpParams();
    params = params.append('groupNumber', groupNumber);
    params = params.append('calendarYear', calendarYear);
    params = params.append('isoReceiptDate', isoReceiptDate);
    return this.http.get(this.baseUrl+"api/scheduleaadhoc/report", {params:params} );
  }


  public getUploadedFile(groupNumber, calendarYear, isoReceiptDate){
    // let headers = new HttpHeaders().append("Authorization", "Bearer " + token)
    let params = new HttpParams();
    params = params.append('groupNumber', groupNumber);
    params = params.append('calendarYear', calendarYear);
    params = params.append('isoReceiptDate', isoReceiptDate);
    return this.http.get(this.baseUrl+"api/schedulec/excel", {responseType: 'arraybuffer', params:params});
}

public getCertiOfCompGroups(){
  return this.http.get(this.baseUrl+"api/certofcompliance/groups" );
}

public getertiOfCompFilters(groupNumber){
  let params = new HttpParams();
  params = params.append('groupNumber', groupNumber);
  return this.http.get(this.baseUrl+"api/certofcompliance/filters", {params:params} );
}

public getCertificateOfComplianceReport(groupNumber, calendarYear, isoReceiptDate){
  let params = new HttpParams();
  params = params.append('groupNumber', groupNumber);
  params = params.append('calendarYear', calendarYear);
  params = params.append('isoReceiptDate', isoReceiptDate);
  return this.http.get(this.baseUrl+"api/certofcompliance/report", {params:params} );
}

public getLossSummaryCalendarYears() {
  return this.http.get(this.baseUrl+"api/LossSummary/filters" );
}

public getLossSummaryReport(calendarYear, asofDate){
  let params = new HttpParams();
  params = params.append('calendarYear', calendarYear);
  params = params.append('asOfDate', asofDate);
  return this.http.get(this.baseUrl+"api/LossSummary/report", {params:params} );
}
public getLossSummaryFile(calendarYear, asOfDate){
  // let headers = new HttpHeaders().append("Authorization", "Bearer " + token)
  let params = new HttpParams();
  params = params.append('calendarYear', calendarYear);
  params = params.append('asOfDate', asOfDate);
  return this.http.get(this.baseUrl+"api/LossSummary/excel", {responseType: 'arraybuffer', params:params});
}

public getExcludingWCCalendarYears() {
  return this.http.get(this.baseUrl+"api/ExcludingWCandRMData/year" );
}
public getExcludingWCCatastrophe() {
  return this.http.get(this.baseUrl+"api/ExcludingWCandRMData/catastrophe" );
}
public getExcludingWCReport(calendarYear, asofDate, catCode){
  let params = new HttpParams();
  params = params.append('calendarYear', calendarYear);
  params = params.append('asofDate', asofDate);
  params = params.append('catCode', catCode);
  return this.http.get(this.baseUrl+"api/ExcludingWCandRMData/report", {params:params} );
}
public getExcludingWCFile(calendarYear, asofDate, catCode){
  let params = new HttpParams();
  params = params.append('calendarYear', calendarYear);
  params = params.append('asofDate', asofDate);
  params = params.append('catCode', catCode);
  return this.http.get(this.baseUrl+"api/ExcludingWCandRMData/excel", {responseType: 'arraybuffer', params:params});
}


//ResidualMarketDataReport services

public getResidualMarketDataReportCalendarYears(): Observable<any> {
  return this.http.get(this.baseUrl+"api/residualmarketdataexclwc/year" );
}
public getResidualMarketDataReportCatastrophe(): Observable<any> {
  return this.http.get(this.baseUrl+"api/residualmarketdataexclwc/catastrophe" );
}
public getResidualMarketDataReport(calendarYear, asofDate, catCode): Observable<any>{
  let params = new HttpParams();
  params = params.append('calendarYear', calendarYear);
  params = params.append('asofDate', asofDate);
  params = params.append('catCode', catCode);
  return this.http.get(this.baseUrl+"api/residualmarketdataexclwc/report", {params:params} );
}
public getResidualMarketDataReportFile(calendarYear, asofDate, catCode): Observable<any>{
  let params = new HttpParams();
  params = params.append('calendarYear', calendarYear);
  params = params.append('asofDate', asofDate);
  params = params.append('catCode', catCode);
  return this.http.get(this.baseUrl+"api/residualmarketdataexclwc/excel", {responseType: 'arraybuffer', params:params});
}




public getNoticeofDeductibleGroups(){
  return this.http.get(this.baseUrl+"api/noticeofdeductible/groups" );
}
public getNoticeofDeductibleCalendarYear(groupNumber){
  let params = new HttpParams();
  params = params.append('groupNumber', groupNumber);
  return this.http.get(this.baseUrl+"api/noticeofdeductible/year", {params:params} );
}
public getNoticeofDeductibleIsoReceiptDate(groupNumber, calendarYear){  
  let params = new HttpParams();
  params = params.append('groupNumber', groupNumber);
  params = params.append('calendarYear', calendarYear);
  return this.http.get(this.baseUrl+"api/noticeofdeductible/isoreceiptdate", {params:params} );
}
public getNoticeofDeductibleReport(groupNumber, calendarYear, isoReceiptDate){
  let params = new HttpParams();
  params = params.append('groupNumber', groupNumber);
  params = params.append('calendarYear', calendarYear);
  params = params.append('isoReceiptDate', isoReceiptDate);
  return this.http.get(this.baseUrl+"api/noticeofdeductible/report", {params:params} );
}

public getEarnedPremiumCalendarYearList(){
  return this.http.get(this.baseUrl + "api/earnedpremium/year");
}

public getEarnedPremiumReport(calendarYear){
  let params = new HttpParams();
  params = params.append('calendarYear', calendarYear);
  return this.http.get(this.baseUrl+"api/earnedpremium/report", {params:params} );
}

public getEarnedPremiumExcel(calendarYear){
  let params = new HttpParams();
  params = params.append('calendarYear', calendarYear);
  return this.http.get(this.baseUrl+"api/earnedpremium/excel", {responseType: 'arraybuffer', params:params});
}

public getCertLossAdHocGroups(){
  return this.http.get(this.baseUrl+"api/certoflossadhoc/groups" );
}
public getCertLossAdHocCalendarYear(groupNumber){ 
  let params = new HttpParams();
  params = params.append('groupNumber', groupNumber); 
  return this.http.get(this.baseUrl+"api/certoflossadhoc/calendaryear", {params:params} );
}
public getCertLossAdHocEvaluationDate(groupNumber, calendarYear,){  
  let params = new HttpParams();
  params = params.append('groupNumber', groupNumber);
  params = params.append('calendarYear', calendarYear);
  return this.http.get(this.baseUrl+"api/certoflossadhoc/evaluationdates", {params:params} );
}
public getCertLossAdHocIsoReceiptDate(groupNumber, calendarYear, evaluationDate){  
  let params = new HttpParams();
  params = params.append('groupNumber', groupNumber);
  params = params.append('calendarYear', calendarYear);
  params = params.append('evaluationDate', evaluationDate);
  return this.http.get(this.baseUrl+"api/certoflossadhoc/isoreceiptdates", {params:params} );
}

public getCertLossAdHocReport(groupNumber, calendarYear, evaluationDate, isoReceiptDate){
  let params = new HttpParams();
  params = params.append('groupNumber', groupNumber);
  params = params.append('calendarYear', calendarYear);
  params = params.append('evaluationDate', evaluationDate);
  params = params.append('isoReceiptDate', isoReceiptDate);
  return this.http.get(this.baseUrl+"api/certoflossadhoc/report", {params:params} );
}

public getPaymentDisbursementGroups(){
  return this.http.get(this.baseUrl + "api/paymentdisbursementinfnadhoc/groups");
}

public getPaymentDisbursementFilters(groupNumber){
  let params = new HttpParams();
  params = params.append('groupNumber', groupNumber);
  return this.http.get(this.baseUrl+"api/paymentdisbursementinfnadhoc/filters", {params:params} );
}

public getPaymentDisbursementReport(groupNumber, calendarYear, isoReceiptDate){
  let params = new HttpParams();
  params = params.append('groupNumber', groupNumber);
  params = params.append('calendarYear', calendarYear);
  params = params.append('isoReceiptDate', isoReceiptDate);
  return this.http.get(this.baseUrl+"api/paymentdisbursementinfnadhoc/report", {params:params} );
}

public getWorkersCompensationCalendarYears() {
  return this.http.get(this.baseUrl+"api/workcompensation/calendaryear" );
}
public getWorkersCompensationCatastrophe() {
  return this.http.get(this.baseUrl+"api/workcompensation/catcode" );
}
public getWorkersCompensationWCReport(calendarYear, asofDate, catCode){
  let params = new HttpParams();
  params = params.append('calendarYear', calendarYear);
  params = params.append('asofDate', asofDate);
  params = params.append('catCode', catCode);
  return this.http.get(this.baseUrl+"api/workcompensation/report", {params:params} );
}
public getWorkersCompensationFile(calendarYear, asofDate, catCode){
  let params = new HttpParams();
  params = params.append('calendarYear', calendarYear);
  params = params.append('asofDate', asofDate);
  params = params.append('catCode', catCode);
  return this.http.get(this.baseUrl+"api/workcompensation/excel", {responseType: 'arraybuffer', params:params});
}
}
