<div class="sidenav non-printable" >
  <div class="head" >Standard Reports</div>

      <!-- <ul>
        <li> <a href="#"> Standard Reports</a></li> -->
        <ul>
        <li style="font-family: arial;font-size: 12px; color: #004b87;font-weight:400;">  Certification of Compliance (Schedule B)
          <ul><li>
             <!-- <a  href="/certofcompliance">Certificate of Compliance Ad Hoc</a> -->
             <a [routerLink]="['/certofcompliance']">Certification of Compliance Ad Hoc</a>
          </li></ul>
          <li style="font-family: arial;font-size: 12px; color: hsl(207, 100%, 26%);font-weight:400;">Certification of Loss
          <ul><li><a [routerLink]="['/certoflossadhoc']">Certification of Loss Ad Hoc</a></li>
            <li>
              <a [routerLink]="['/certoflosssummary']">Summary Based on Certification of Loss</a>
            </li>
            </ul>
          </li>
          <li style="font-family: arial;font-size: 12px; color: #004b87;font-weight:400;">Declaration of Direct Earned Premium and Calculation of Insurer Deductible (Schedule A)</li>
          <ul>
            <li>
              <a [routerLink]="['/schedulea']">Schedule A Ad Hoc</a>
              <!-- <a href="/schedulea">Schedule A Ad Hoc</a> -->
            </li>
            <li><a [routerLink]="['/earnedpremium']">Total Earned Premiums for Individual Insurer Groups</a></li>
          </ul>
          <li style="font-family: arial;font-size: 12px; color: #004b87;font-weight:400;">Loss Bordereau (Schedule C)</li>
          <ul>
            <li>
              <!-- <a href="/excludingworkerscompensation">Excluding Workers Compensation and Residual Market Data</a> -->
              <a [routerLink]="['/excludingworkerscompensation']">Excluding Workers Compensation and Residual Market Data</a>
            </li>
            <li>
              <!-- <a href="/schedulec">Loss Bordereau (Schedule C) Ad Hoc</a> -->
              <a [routerLink]="['/schedulec']">Loss Bordereau (Schedule C) Ad Hoc</a>
            </li>
            <li><a [routerLink]="['/residualMarketData']">Residual Market Data (excluding Workers Compensation)</a></li>
            <li><a [routerLink]="['/workerscompensation']">Workers Compensation (including Residual Market Data)</a></li>
          </ul>

            <li style="font-family: arial;font-size: 12px; color: #004b87;font-weight:400;">Notice of Deductible Erosion</li>
            <ul>
            <li><a [routerLink]="['/noticeofdeductible']">Notice of Deductible Erosion</a></li>
          </ul>
          <li style="font-family: arial;font-size: 12px; color: #004b87;font-weight:400;">Payment Disbursement Information (PDI)</li>
          <ul>
            <li><a [routerLink]="['/paymentdisbursement']">Payment Disbursement Information Ad Hoc</a></li>
          </ul>
        </ul>
  <!-- </ul> -->
  <!-- <div>
    <app-treeview></app-treeview>
  </div> -->
  </div>
