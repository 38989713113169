<div class="rprtHeader non-printable">Schedule A Ad Hoc</div>
<div class="rprtHeaderSub non-printable">Selection Criteria</div>

<div class="filterBox non-printable">


    <div class="inputcontainer">
        <label for="username">Group Name</label>
        <select id="filter-group" class="selectLongInput"  [(ngModel)]="selectedGroup" (change)="onGroupChange()">
            <option *ngFor="let group of groupList" [value]="group.groupNumber">{{group.groupName}}</option>
        </select>
    </div>
    <div class="inputcontainer">
    <label for="username">Calendar Year</label>
        <select id="filter-calendar-year" class="selectShortInput" [(ngModel)]="selectedCalendarYear" (change)="onCalendarYearChange()">
            <option value="-1">--Select--</option>
            <option *ngFor="let calendarYear of calendarYearList" [value]="calendarYear">{{calendarYear}}</option>
        </select>
    </div>
    <div class="inputcontainer">
        <label for="username">ISO Receipt Date</label>
        <select id="filter-iso-recceipt-date"  class="selectInput"  [(ngModel)]="selectedIsoReceiptDate" (change)="onIsoReceiptDateChange()">
            <option value="-1">--Select--</option>
            <option *ngFor="let isoReceiptDateItem of isoReceiptDateList" [value]="isoReceiptDateItem.isoReceiptDate">{{isoReceiptDateItem.isoReceiptDate}}</option>
        </select>
    </div>
    <div class="inputcontainer">
      <label for="username">Format</label>
     <select [(ngModel)]="selectedReportType" class="selectShortInput1">
          <option *ngFor="let type of reportTypes" [value]="type.value">{{type.text}}</option>
      </select>
  </div>
 <button type="button" (click)="generateReport();" class="newButton rpt" >View Report</button>

</div>
<div id ="schedulea-report-id" class="tableWrapper"  #reportElement *ngIf="showGrid">

    <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt' class='x1'>
        <!-- <tr><td class='x1'>&nbsp;</td></tr> -->
        <tr>
            <td style='padding-left:0pt' class='x1'>
                <table border=0 cellspacing=0 cellpadding=0 width="100%">
                    <tr>
                        <td align=RIGHT>
                            <span class='x9'> Control No. </span>
                            <span class='x11'>{{scheduleAReport.controlNo}}</span>
                            <span class='x10'> </span>
                        </td>
                    </tr>
                    <tr style="line-height: 1;">
                        <td class='x13'>&nbsp;</td>
                    </tr>
                    <tr>
                        <td class='x15'>Schedule A</td>
                    </tr>
                    <tr style="line-height: 1;">
                        <td class='x18'> Terrorism Risk Insurance Program</td>
                    </tr>
                    <tr style="line-height: 1;">
                        <td align=CENTER>
                            <span class='x22'>{{scheduleAReport.groupName}}</span>
                            <span class='x21'> </span>
                        </td>
                    </tr>
                    <tr>
                        <td class='x-9 lh-1_1'>&nbsp;</td>
                    </tr>
                    <tr>
                        <td class='x-9 lh-1_1'>&nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>NAIC Insurer (or Group) Number (or TIN if no NAIC #): </span>
                            <span>{{scheduleAReport.naicInsurerorGroupNumber}}</span>
                            <span> </span>
                        </td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>Calendar Year:  </span>
                            <span>{{scheduleAReport.calendarYear}}</span>
                            <span> </span>
                        </td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>Calendar Year of Direct Earned Premium: </span>
                            <span>{{scheduleAReport.calendarYearOfDirectEarnedPremium}}</span>
                            <span>  </span>
                        </td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>Date Received at ISO: </span>
                            <span>{{scheduleAReport.dateReceivedAtISO}}</span>
                            <span> </span>
                        </td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>Submitted By: </span>
                            <span>{{scheduleAReport.submittedBy}}</span>
                            <!-- <span class='x38'>  </span>
                            <span class='x41'>Messick</span>
                            <span class='x39'> </span> -->
                        </td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>Title: </span>
                            <span>{{scheduleAReport.title}}</span>
                            <span> </span>
                        </td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>Signed On: </span>
                            <span>{{scheduleAReport.signedOn}}</span>
                            <span> </span>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

    <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt' class='x51'>
        <col>
        <col>
        <tr class="x-9 lh-1_1">
            <td colspan=2>&nbsp;</td>
        </tr>
        <tr class="x-9 lh-1_1">
            <td style='padding-left:0pt' colspan=2>
                <table border=0 cellspacing=0 cellpadding=0 width="100%">
                    <tr class='x-10 b'>
                        <td>Affiliated Insurers in the Group:</td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr class="x-9 b ta-c va-b lh-1_1">
            <td><u>Affiliated Insurer Name</u></td>
            <td><u>Affiliated Insurer Number</u></td>
        </tr>
        <tr class="lh-1_1" *ngFor="let insurer of scheduleAReport.affiliatedInsurersInTheGroup; let i = index">
            <td [ngClass]="{ 'x53_0' : i % 2==0, 'x53_1' : i % 2 !=0 }" >{{insurer.affiliatedInsurerName}}</td>
            <td [ngClass]="{ 'x52_0' : i % 2==0, 'x52_1' : i % 2 !=0 }" >{{insurer.affiliatedInsurerNumber}}</td>
        </tr>
        </table>

        <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt' class='x-9 ta-l'>
            <col><col><col>
            <tr>
                <td colspan=3>&nbsp;</td>
            </tr>
            <tr>
                <td style='padding-left:0pt' colspan=3>
                    <table border=0 cellspacing=0 cellpadding=0 width="100%">
                        <tr class="x-9 b ta-l">
                            <td>
                                <span>Direct Earned Premium (</span>
                                <span class='i'> Step 1 </span>
                                <span>):</span>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr class="x-9 b va-b ta-c lh-1_1">
                <td><u>LOB Code</u></td>
                <td><u>LOB Description</u></td>
                <td><u>Direct Earned Premium</u></td>
            </tr>
            <tr class="lh-1_2" *ngFor="let lob of scheduleAReport.directEarnedPremiumList; let i = index">
                <td [ngClass]="{ 'x62_0' : i % 2==0, 'x62_1' : i % 2 !=0 }" >{{lob.lobCode}}</td>
                <td [ngClass]="{'x60_0' : i % 2==0, 'x60_1' : i % 2 !=0}">{{lob.lobDescription}}</td>
                <td [ngClass]="{'x61_0' : i % 2==0, 'x61_1' : i % 2 !=0}">${{lob.directEarnedPrem}}</td>
            </tr>

            <tr class="lh-1_2">
                 <td colspan=3>&nbsp;</td>
            </tr>
            <tr>
                <td style='padding-left:0pt' colspan=3>
                    <table border=0 cellspacing=0 cellpadding=0 width="100%">
                        <tr class="x-9 b ta-l lh-1_1">
                            <td>
                                <span>Total Direct Earned Premium (</span>
                                <span class='i'> Step 1 </span>
                                <span>): </span>
                                <span>${{scheduleAReport.totalDirectEarnedPremiumStep1}}</span>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>



<table cellpadding=1 style='width: 101%; margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt' class='x89'>
    <tr class="lh-1_1">
        <td colspan=8>&nbsp;</td>
    </tr>
    <tr>
        <td style='padding-left:0pt' colspan=8 class='x89'>
            <table border=0 cellspacing=0 cellpadding=0 width="100%">
                <tr class="x-9 b lh-1_1">
                    <td>
                        <span>Premium Excluded for TRIA (</span>
                        <span class='i'> Step 2 </span>
                        <span>):</span>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    <tr class="x-9 b ta-c lh-1_2">
        <td class="va-b"><u>LOB Code</u></td>
        <td class="va-b"><u>LOB Description</u></td>
        <td class="w-137 va-b"><u>Exclusion Amount 1</u></td>
        <td class="w-137 va-b"><u>Exclusion Reason 1</u></td>
        <td class="w-137 va-b"><u>Exclusion Amount 2</u></td>
        <td class="w-137 va-b"><u>Exclusion Reason 2</u></td>
        <td class="w-137 va-b"><u>Exclusion Amount 3</u></td>
        <td class="w-137 va-b"><u>Exclusion Reason 3</u></td>
    </tr>
    <tr class="lh-1_2" *ngFor="let exclusion of scheduleAReport.premiumExcludedForTRIAList; let i = index">
        <td [ngClass]="{ 'x97_0' : i % 2==0, 'x97_1' : i % 2 !=0 }" >{{exclusion.lobCode}}</td>
        <td [ngClass]="{ 'x95_0' : i % 2==0, 'x95_1' : i % 2 !=0 }" >{{exclusion.lobDescription}}</td>
        <td [ngClass]="{ 'x90_0' : i % 2==0, 'x90_1' : i % 2 !=0 }" >${{exclusion.exclusionAmount1}}</td>
        <td [ngClass]="{ 'x96_0' : i % 2==0, 'x96_1' : i % 2 !=0 }" >{{exclusion.exclusionReason1}}</td>
        <td [ngClass]="{ 'x98_0' : i % 2==0, 'x98_1' : i % 2 !=0 }" >${{exclusion.exclusionAmount2}}</td>
        <td [ngClass]="{ 'x91_0' : i % 2==0, 'x91_1' : i % 2 !=0 }" >{{exclusion.exclusionReason2}}</td>
        <td [ngClass]="{ 'x92_0' : i % 2==0, 'x92_1' : i % 2 !=0 }" >${{exclusion.exclusionAmount3}}</td>
        <td [ngClass]="{ 'x93_0' : i % 2==0, 'x93_1' : i % 2 !=0 }" >{{exclusion.exclusionReason3}}</td>
    </tr>
<tr class="lh-1_1">
    <td colspan=8>&nbsp;</td>
</tr>
<tr *ngIf="scheduleAReport.totalPremiumExcludedForTRIAStep2.length > 0 && scheduleAReport.totalPremiumExcludedForTRIAStep2 != '0'">
    <td style='padding-left:0pt' colspan=8>
        <table border=0 cellspacing=0 cellpadding=0 width="100%">
            <tr class="x-9 b lh-1_1">
                <td>
                    <span>Total Premium Excluded for TRIA (</span>
                    <span class="i"> Step 2 </span>
                    <span>): </span>
                    <span>${{scheduleAReport.totalPremiumExcludedForTRIAStep2}}</span>
                </td>
            </tr>
        </table>
    </td>
</tr>
</table>

<table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
    <col><col><col><col><col>
    <tr class="lh-1_1">
        <td colspan=5>&nbsp;</td>
    </tr>
    <tr>
        <td style='padding-left:0pt' colspan=5>
            <table border=0 cellspacing=0 cellpadding=0 width="100%">
                <tr class="x-9 b lh-1_1">
                    <td>
                        <span>Premium Ceded to Residual Markets (</span>
                        <span class='i'> Step 3 </span>
                        <span>):  </span>
                        <span> </span>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    <tr class="x-9 b ta-c lh-1_2">
        <td class="va-b"><u>LOB Code</u></td>
        <td class="va-b ta-l"><u>LOB Description</u></td>
        <td class="va-b"><u>State Code</u></td>
        <td class="va-b"><u>Direct Earned Premium Ceded to Residual Market</u></td>
        <td class="va-b"><u>Residual Market Name</u></td>
    </tr>
    <tr class="lh-1_2" *ngFor="let residual of scheduleAReport.premiumCededToResidualMarketsList; let i = index">
        <td [ngClass]="{ 'x124_0' : i % 2==0, 'x124_1' : i % 2 !=0 }" >{{residual.lobCd}}</td>
        <td [ngClass]="{ 'x121_0' : i % 2==0, 'x121_1' : i % 2 !=0 }" >{{residual.lobDesc}}</td>
        <td [ngClass]="{ 'x123_0' : i % 2==0, 'x123_1' : i % 2 !=0 }"  >{{residual.stateCd}}</td>
        <td [ngClass]="{ 'x118_0' : i % 2==0, 'x118_1' : i % 2 !=0 }" >${{residual.directEarnedPremiumCededToResidualMarket}}</td>
        <td [ngClass]="{ 'x117_0' : i % 2==0, 'x117_1' : i % 2 !=0 }" >{{residual.residualMarketName}}</td>
    </tr>
    <tr *ngIf="scheduleAReport.totalPremiumCededToResidualMarketsStep3.length > 0 && scheduleAReport.totalPremiumCededToResidualMarketsStep3 != '0'">
        <td style='padding-left:0pt' colspan=5>
            <table border=0 cellspacing=0 cellpadding=0 width="100%">
                <tr class="lh-1">
                    <td>&nbsp;</td>
                </tr>
                <tr class="x-9 b lh-1_1">
                    <td>
                        <span>Total Premium Ceded to Residual Markets (</span>
                        <span class="i"> Step 3 </span>
                        <span>): </span>
                        <span>${{scheduleAReport.totalPremiumCededToResidualMarketsStep3}}</span>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>

<table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
    <col><col><col><col><col>
    <tr class="lh-1">
        <td colspan=5>&nbsp;</td>
    </tr>
    <tr>
        <td style='padding-left:0pt' colspan=5>
            <table border=0 cellspacing=0 cellpadding=0 width="100%">
                <tr class="x-9 b lh-1_1">
                    <td>
                        <span>Premium Received from Residual Markets (</span>
                        <span class='i'> Step 4 </span>
                        <span>):</span>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    <tr class="x-9 b ta-c lh-1_1">
        <td class="va-b"><u>LOB Code</u></td>
        <td class="va-b ta-l"><u>LOB Description</u></td>
        <td class="va-b"><u>State Code</u></td>
        <td align=RIGHT class="va-b"><u>Earned Premium Received From Residual Market</u></td>
        <td class="va-b"><u>Residual Market Name</u></td>
    </tr>
    <tr class="lh-1_2" *ngFor="let premiumReceived of scheduleAReport.premiumReceivedFromResidualMarketsList; let i = index">
        <td [ngClass]="{ 'x146_0' : i % 2==0, 'x146_1' : i % 2 !=0 }" >{{premiumReceived.lobCd}}</td>
        <td [ngClass]="{ 'x147_0' : i % 2==0, 'x147_1' : i % 2 !=0 }" >{{premiumReceived.lobDesc}}</td>
        <td [ngClass]="{ 'x145_0' : i % 2==0, 'x145_1' : i % 2 !=0 }" >{{premiumReceived.stateCd}}</td>
        <td [ngClass]="{ 'x144_0' : i % 2==0, 'x144_1' : i % 2 !=0 }" >${{premiumReceived.earnedPremiumReceivedFromResidualMarket}}</td>
        <td [ngClass]="{ 'x143_0' : i % 2==0, 'x143_1' : i % 2 !=0 }" >{{premiumReceived.residualMarketName}}</td>
    </tr>
    <tr *ngIf = "scheduleAReport.totalPremiumReceivedFromResidualMarketsStep4.length > 0 && scheduleAReport.totalPremiumReceivedFromResidualMarketsStep4 != '0'">
        <td style='padding-left:0pt' colspan=5>
            <table border=0 cellspacing=0 cellpadding=0 width="100%">
                <tr class="lh-1_1">
                    <td>&nbsp;</td>
                </tr>
                <tr class="x-9 b lh-1_1">
                    <td>
                        <span>Total Premium Received from Residual Markets (</span>
                        <span class='i'> Step 4 </span>
                        <span>): </span>
                        <span>${{scheduleAReport.totalPremiumReceivedFromResidualMarketsStep4}}</span>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>

<table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
    <tr class="lh-1">
        <td>&nbsp;</td>
    </tr>
    <tr>
        <td style='padding-left:0pt'>
            <table border=0 cellspacing=0 cellpadding=0 width="100%">
                <tr class="x-10 b lh-1_1">
                    <td>STEP 5:</td>
                </tr>
                <tr class="lh-0_9">
                    <td>&nbsp;</td>
                </tr>
                <tr class="x-9 lh-1">
                    <td>
                        <span>(Total Direct Earned Premium (</span>
                        <span class='i'> Step 1 </span>
                        <span>) </span>
                        <span> </span>
                        <span class='b'>${{scheduleAReport.totalDirectEarnedPremiumStep1}} </span>
                        <span>  </span>
                        <span>  </span>
                        <span>plus Premium Received from Residual Markets (</span>
                        <span class='i'> Step 4 </span>
                        <span>) </span>
                        <span> </span>
                        <span class='b'>${{scheduleAReport.totalPremiumReceivedFromResidualMarketsStep4}} </span>
                        <span> </span>
                        <span class='i'>)</span>
                    </td>
                </tr>
                <tr class="lh-0_9">
                    <td>&nbsp;</td>
                </tr>
                <tr class="x-9 lh-1">
                    <td>
                        <span> </span>
                        <span>SUBTRACT</span>
                    </td>
                </tr>
                <tr class="lh-0_9">
                    <td>&nbsp;</td>
                </tr>
                <tr class="x-9 lh-1">
                    <td>
                        <span>(Premium Excluded for TRIA (</span>
                        <span class='i'> Step 2 </span>
                        <span>) </span>
                        <span class='b'>${{scheduleAReport.totalPremiumExcludedForTRIAStep2}} </span>
                        <span>  </span>
                        <span> </span>
                        <span> </span>
                        <span>plus Premium Ceded to Residual Markets (</span>
                        <span class='i'> Step 3 </span>
                        <span>) </span>
                        <span> </span>
                        <span class='b'>${{scheduleAReport.totalPremiumCededToResidualMarketsStep3}} </span>
                        <span> </span>
                        <span class='i'>)</span>
                    </td>
                </tr>
                <tr class="lh-0_9">
                    <td>&nbsp;</td>
                </tr>
                <tr class="x-9 lh-1">
                    <td>EQUALS</td>
                </tr>
                <tr class="lh-0_9">
                    <td>&nbsp;</td>
                </tr>
                <tr class="x-9 lh-1">
                    <td>
                        <span>TRIA Direct Earned Premium: </span>
                        <span class='b'>${{scheduleAReport.triaDirectEarnedPremium}}</span>
                        <span> </span>
                        <span> </span>
                    </td>
                </tr>
                <tr class="lh-0_9">
                    <td> &nbsp;</td>
                </tr>
                <tr class="x-9 lh-1">
                    <td>
                        <span>MULTIPLY X </span>
                        <span class='b'>{{scheduleAReport.deductibleFctr}}</span>
                        <span> Deductible Factor</span>
                    </td>
                </tr>
                <tr class="lh-0_9">
                    <td>&nbsp;</td>
                </tr>
                <tr class="x-9 lh-1">
                    <td>
                        <span>EQUALS </span>
                        <span class='b'>${{scheduleAReport.totalofStep1andStep4AsStep5}}</span>
                        <span> </span>
                        <span> Insurer Deductible under TRIA</span>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>

</div>
