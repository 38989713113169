import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ReportService } from '../../report.service';
import { Observable } from 'rxjs';
import { TripAlertService } from '../../trip-alert.service';

@Component({
  selector: 'app-certificationoflossadhoc',
  templateUrl: './certificationoflossadhoc.component.html',
  styleUrls: ['./certificationoflossadhoc.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CertificationoflossadhocComponent implements OnInit {
  selectedGroup: any;
  selectedCalendarYear: any;
  selectedEvaluationDate: any;
  selectedIsoReceiptDate: any;
  certLossAdhocReport: any;
  groupList: any = [];
  // filterList: any = [];

  groupNumber: string;
  calendarYear: any;
  isoReceiptDate: any;
  calendarYearList: any = [];
  evaluationDateList: any = [];
  isoReceiptDateList: any = [];
  showGrid: boolean = false;
  filteredGroups: Observable<string[]>;
  reportTypes: any = [
    { value: 'html', text: 'Html' },
    { value: 'pdf', text: 'PDF' },
  ];
  selectedReportType: string = 'html';

  constructor(
    private reportSvc: ReportService,
    private alert: TripAlertService,
    private ChangeRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getGroups();
  }
  getGroups() {
    this.selectedCalendarYear = '-1';
    this.selectedEvaluationDate = '-1';
    this.selectedIsoReceiptDate = '-1';
    this.showGrid = false;
    this.reportSvc.getCertLossAdHocGroups().subscribe(
      (res) => {
        if (res) {
          this.groupList = res;
          this.selectedGroup = this.groupList[0].groupNumber;
          this.getCalendarYear();
        }
      },
      () => {}
    );
  }
  onGroupChange() {
    this.selectedCalendarYear = '-1';
    this.evaluationDateList = [];
    this.isoReceiptDateList = [];
    this.showGrid = false;
    this.getCalendarYear();
  }
  getCalendarYear() {
    this.reportSvc.getCertLossAdHocCalendarYear(this.selectedGroup).subscribe(
      (res) => {
        if (res) {
          this.calendarYearList = res;
        }
      },
      () => {}
    );
  }
  onCalendarYearChange() {
    this.isoReceiptDateList = [];
    this.showGrid = false;
    if (this.selectedCalendarYear == '-1') {
      this.evaluationDateList = [];
    } else {
      this.getEvaluationDate();
    }
    this.selectedEvaluationDate = '-1';
  }
  getEvaluationDate() {
    this.reportSvc
      .getCertLossAdHocEvaluationDate(
        this.selectedGroup,
        this.selectedCalendarYear
      )
      .subscribe(
        (res) => {
          if (res) {
            this.evaluationDateList = res;
          }
        },
        () => {}
      );
  }
  onEvaluationDateChange() {
    this.selectedIsoReceiptDate = '-1';
    this.showGrid = false;
    if (this.selectedEvaluationDate == '-1') {
      this.isoReceiptDateList = [];
    } else {
      this.getIsoReceiptDate();
    }
  }
  getIsoReceiptDate() {
    this.reportSvc
      .getCertLossAdHocIsoReceiptDate(
        this.selectedGroup,
        this.selectedCalendarYear,
        this.selectedEvaluationDate
      )
      .subscribe(
        (res) => {
          if (res) {
            this.isoReceiptDateList = res;
          }
        },
        () => {}
      );
  }
  onIsoReceiptDateChange() {
    this.showGrid = false;
  }
  generateReport() {
    if (this.selectedCalendarYear === '-1') {
      this.alert.alert('Please select Calendar Year');
      return;
    }
    if (this.selectedEvaluationDate === '-1') {
      this.alert.alert('Please select Evaluation Date');
      return;
    }
    if (this.selectedIsoReceiptDate === '-1') {
      this.alert.alert('Please select ISO Receipt Date');
      return;
    }
    var aspdf = false;
    if (this.selectedReportType === 'pdf') {
      aspdf = true;
    }
    this.getCertLossAdhocReport(aspdf);
  }
  getCertLossAdhocReport(aspdf = false) {
    this.reportSvc
      .getCertLossAdHocReport(
        this.selectedGroup,
        this.selectedCalendarYear,
        this.selectedEvaluationDate,
        this.selectedIsoReceiptDate
      )
      .subscribe(
        (res) => {
          if (res) {
            this.certLossAdhocReport = res;
            this.showGrid = true;
            if (aspdf) {
              this.ChangeRef.detectChanges();
              this.generatePdf();
              // this.showGrid = false;
            }
          }
        },
        () => {}
      );
  }
  generatePdf() {
    var _title = document.title;
    document.title = 'Certification of Loss Ad Hoc';
    window.print();
    document.title = _title;
  }
}
