import { Component, OnInit } from '@angular/core';

import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';


interface FoodNode {
  name: string;
  children?: FoodNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Treasury',
    children: [
      { name: 'Standard Reports' , children : [{
        name:'Certification of Compliance (Schedule B) Ad Hoc', children :[{
          name:'Certification of Compliance Ad Hoc'
        }]
        
      },
      {name:'Certification of Loss',children :[{name:'Certification of Loss Ad Hoc'},{name:'Summary Based on Certification of Loss'}]},
      {name:'Declaration of Direct Earned Premium and Calculation of Insurer Deductible (Schedule A)',children :[{name:'Schedule A Ad Hoc'},{name:'Total Earned Premiums for Individual Insurer Groups'}]},
      {name:'Loss Bordereau (Schedule C)',children :[{name:'Excluding Workers Compensation and Residual Market Data'},{name:'Loss Bordereau (Schedule C) Ad Hoc'},{name:'Residual Market Data (excluding Workers Compensation)'}]},
      {name:'Notice of Deductible Erosion',children :[{name:'Notice of Deductible Erosion'}]},
      {name:'Payment Disbursement Information (PDI)',children :[{name:'Payment Disbursement Information Ad Hoc'}]},   

      ] },
     
      
    ]
  }
];

@Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss']
})
export class TreeviewComponent implements OnInit {

  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor() {
    this.dataSource.data = TREE_DATA;
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  navigate(node){

  }


  ngOnInit(): void {
  }

}
