import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class TripAlertService {
  constructor() {}

  public alert(msg, title = '') {
    return Swal.fire({
      title: title,
      text: msg,
      allowOutsideClick:false,
      confirmButtonColor: '#006ba6',
      customClass: {
        confirmButton: 'cnfrm-btn',
      },
    });
  }

  public warn(msg, title = '') {
    Swal.fire({
      title: title,
      allowOutsideClick:false,
      text: msg,
      confirmButtonColor: '#006ba6',
      icon: 'warning',
      customClass: {
        confirmButton: 'cnfrm-btn',
      },
    });
  }
  public error(msg, title = '') {
    Swal.fire({
      title: title,
      text: msg,
      allowOutsideClick: false,
      confirmButtonColor: '#006ba6',
      icon: 'error',
      customClass: {
        confirmButton: 'cnfrm-btn',
      },
    });
  }
  public confirm(msg, title = '', options = {}) {
    return new Observable((observer) => {
      Swal.fire({
        title: title,
        text: msg,
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
        ...options,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          observer.next(result);
        } else if (result.isDenied || result.isDismissed) {
          observer.error(result);
        }
      });
    });
  }
}
