
<div class="rprtHeader">Loss Bordereau (Schedule C) Ad Hoc</div>
<div class="rprtHeaderSub">Selection Criteria</div>

<div class="filterBox">


        <div class="inputcontainer">
            <label for="username">Group Name</label>
            <select id="filter-group" class="selectLongInput"  [(ngModel)]="selectedGroup" (change)="onGroupChange()" style="width: 135px;">
                <option *ngFor="let group of groupList" [value]="group.groupNumber">{{group.groupName}}</option>
            </select>
        </div>
        <div class="inputcontainer">
        <label for="username">Calendar Year</label>
            <select id="filter-calendar-year" class="selectShortInput" [(ngModel)]="selectedCalendarYear" (change)="onCalendarYearChange()">
                <option value="-1">--Select--</option>
                <option *ngFor="let calendarYear of calendarYearList" [value]="calendarYear">{{calendarYear}}</option>
            </select>
        </div>
        <div class="inputcontainer">
            <label for="username">ISO Receipt Date</label>
            <select id="filter-iso-recceipt-date"  class="selectInput"  [(ngModel)]="selectedIsoReceiptDate" (change)="onIsoReceiptDateChange()">
                <option value="-1">--Select--</option>
                <option *ngFor="let isoReceiptDateItem of isoReceiptDateList" [value]="isoReceiptDateItem.isoReceiptDate">{{isoReceiptDateItem.isoReceiptDate}}</option>
            </select>
        </div>

        <div class="inputcontainer">
            <label for="username">Format</label>
           <select [(ngModel)]="selectedReportType" class="selectShortInput1">
                <option *ngFor="let type of reportTypes" [value]="type.value">{{type.text}}</option>
            </select>
        </div>

     <button type="button" (click)="generateReport();" class="newButton rpt" >View Report</button>

</div>


<div id ="schedulec-report-id" class="tableWrapper"  *ngIf="showGrid">
<table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
    <tr>
        <td style='padding-left:0pt' colspan=34>
            <table class="header-box" border=0 cellspacing=0 cellpadding=0 width="100%">
                <tr>
                    <td class='x70'>
                        Terrorism Risk Insurance Program
                    </td>
                </tr>
                <tr>
                    <td class='x71'>
                        Loss Bordereau (Schedule C) Ad Hoc Detail
                    </td>
                </tr>
                <tr>
                    <td class='x72'>
                        &nbsp;
                    </td>
                </tr>
                <tr class="header-bow-row">
                    <td>
                        <span class='x74'>Group: </span>
                        <span class='x77'>{{schedulecReport.group}} </span>
                    </td>
                </tr>
                <tr class="header-bow-row">
                    <td>
                        <span class='x78'>NAIC Insurer (or Group) Number (or TIN if no NAIC #): </span>
                        <span class='x81'>{{schedulecReport.naiC_Insurer_Number}}</span>
                        <span class='x79'> </span>
                    </td>
                </tr>
                <tr class="header-bow-row">
                    <td>
                        <span class='x82'>As of Date: </span>
                        <span class='x85'>{{schedulecReport.date}}</span>
                        <span class='x83'>  </span>
                    </td>
                </tr>
                <tr class="header-bow-row">
                    <td>
                        <span class='x87'>ISO Receipt Date: </span>
                        <span class='x90'>{{schedulecReport.isO_Receipt_Date}}</span>
                        <span class='x88'> </span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span class='x91'>Calendar Year: </span>
                        <span class='x93'>{{schedulecReport.calender_Year}}</span>
                        <span class='x92'> </span>
                    </td>
                </tr>
                <tr>
                    <td>
                        &nbsp;
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    <ng-template ngFor let-catastrophe [ngForOf]="schedulecReport.catastrophes"; let-catIndex=index>
        <tr class="grid-header-row">
            <td class='grid-header-column x41'>
                <pre><u>Cat
Cd</u></pre>
            </td>
            <td  class='grid-header-column x42'>
                <pre><u>LOB
Cd</u></pre>
            </td>
            <td class='grid-header-column x43'>
                <pre><u>Loss
St
Cd</u></pre>
            </td>
            <td class='grid-header-column x36'>
                <pre><u>Certified
Event
Date</u></pre>
            </td>
            <td class='grid-header-column x39'>
                <pre><u>Affiliated
Insurer
Number</u></pre>
            </td>
            <td class='grid-header-column x40'>
                <pre><u>Affiliated
Insurer
Name</u></pre>
            </td>
            <td class='grid-header-column x44'>
                <pre><u>Claim
Num</u></pre>
            </td>
            <td class='grid-header-column x45'>
                <pre><u>Insured
Name</u></pre>
            </td>

            <td class='grid-header-column x46'>
                <pre><u>Insured
TIN</u></pre>
            </td>
            <td class='grid-header-column x47'>
                <u>Policy Eff Date</u>
            </td>
            <td class='grid-header-column x48'>
                <u>Policy Exp Date</u>
            </td>
            <td class='grid-header-column x37'>
                <pre><u>Workers
Comp
Ind</u></pre>
            </td>

            <td class='grid-header-column x38'>
                <pre><u>Num of
Workers
Comp
Claimants</u></pre>
            </td>
            <td class='grid-header-column x49'>
                <pre><u>Prior
Cum
Paid
Loss</u></pre>
            </td>
            <td class='grid-header-column x50'>
                <pre><u>Current
Loss Paid
Amt</u></pre>
            </td>
            <td class='grid-header-column x51'>
                <pre><u>Current
Loss to
be Paid
Amt</u></pre>
            </td>
            <td class='grid-header-column x52'>
                <pre><u>Total
Cumulative
Loss
Payments</u></pre>
            </td>

            <td class='grid-header-column x53'>
                <pre><u>Punitive
Damages
Paid</u></pre>
            </td>
            <td class='grid-header-column x54'>
                <pre><u>ALAE
Paid</u></pre>
            </td>
            <td class='grid-header-column x55'>
                <pre><u>Salvage
Recovered</u></pre>
            </td>
            <td class='grid-header-column x56'>
                <pre><u>Subrogation
Recovered</u></pre>
            </td>
            <td class='grid-header-column x57'>
                <pre><u>Sal and
Sub
Recovered</u></pre>
            </td>
            <td class='grid-header-column x58'>
                <pre><u>Reins
Recovery
Ind</u></pre>
            </td>
            <td class='grid-header-column x59'>
                <pre><u>Dup
Fed
Comp
Ind</u></pre>
            </td>
            <td class='grid-header-column x60'>
                <pre><u>Dup
Fed
Comp
Amt 1</u></pre>
            </td>
            <td class='grid-header-column x61'>
                <pre><u>DupFed
Comp
Amt
Srce
Cd 1</u></pre>
            </td>
            <td class='grid-header-column x62'>
                <pre><u>Dup
Fed
Comp
Amt 2</u></pre>
            </td>
            <td class='grid-header-column x63'>
                <pre><u>DupFed
Comp
Amt
Srce
Cd 2</u></pre>
            </td>
            <td class='grid-header-column x64'>
                <pre><u>Third
Party
Ind</u></pre>
            </td>
            <td class='grid-header-column x65'>
                <pre><u>Claim
Stat
Cd</u></pre>
            </td>
            <td class='grid-header-column x66'>
                <pre><u>Case
Reserves</u></pre>
            </td>
            <td class='grid-header-column x67'>
                <pre><u>Date of
Latest
Payment</u></pre>
            </td>
            <td class='grid-header-column x68'>
                <pre><u>Settlement
Date</u></pre>
            </td>
            <td class='grid-header-column x69'>
                <pre><u>Total
Unprorated
Loss
Amount</u></pre>
            </td>
        </tr>
        <ng-template ngFor let-lob [ngForOf]="catastrophe.lob"; let-lobIndex=index>
            <ng-template ngFor let-state [ngForOf]="lob.state"; let-stateIndex=index>
                <ng-template ngFor let-trip [ngForOf]="state.trip" ; let-tripIndex=index>
                    <ng-template [ngIf]="tripIndex == 0 && (catIndex !==0 || lobIndex !== 0 || stateIndex !== 0)">
                        <tr><td colspan=34>&nbsp;</td></tr>
                    </ng-template>
                    <tr class="grid-data-row">
                        <td>{{trip.cat_Code}}</td>
                        <td>{{trip.loB_Code}}</td>
                        <td>{{trip.state_Code}}</td>
                        <td>{{trip.certified_Event_Date}}</td>
                        <td>{{trip.affiliated_Insurer_Number}}</td>
                        <td style="text-align:left;">{{trip.affiliated_Insurer_Name}}</td>
                        <td style="text-align:left;">{{trip.claim_Nmber}}</td>
                        <td style="text-align:left;">{{trip.insured_Name}}</td>
                        <td>{{trip.insured_TIN}}</td>
                        <td>{{trip.policy_Eff_Date}}</td>
                        <td>{{trip.policy_Exp_Date}}</td>
                        <td>{{trip.workers_Comp_Ind}}</td>
                        <td class="textright">{{trip.workers_Number}}</td>
                        <td class="textright">${{trip.prior_Cum_Paid_Loss}}</td>
                        <td class="textright">${{trip.current_Loss_Paid_Amount}}</td>
                        <td class="textright">${{trip.current_Loss_To_Be_Paid_Amount}}</td>
                        <td class="textright">${{trip.total_Cumulative_Loss_Payments}}</td>
                        <td class="textright">${{trip.punitive_Damages_Paid}}</td>
                        <td class="textright">${{trip.alaE_Paid}}</td>
                        <td class="textright">${{trip.salvage_Recovered}}</td>
                        <td class="textright">${{trip.subrogation_Recovered}}</td>
                        <td class="textright">${{trip.sal_and_Sub_Recovered}}</td>
                        <td>{{trip.reins_Recovery_Ind}}</td>
                        <td>{{trip.dup_Fed_Comp_Ind}}</td>
                        <td class="textright">${{trip.dup_Fed_Comp_Amt_1}}</td>
                        <td>{{trip.dupFed_Comp_Amt_Srce_Cd_1}}</td>
                        <td class="textright">${{trip.dup_Fed_Comp_Amt_2}}</td>
                        <td>{{trip.dupFed_Comp_Amt_Srce_Cd_2}}</td>
                        <td>{{trip.third_Party_Ind}}</td>
                        <td>{{trip.claim_Stat_Cd}}</td>
                        <td class="textright">${{trip.case_Reserves}}</td>
                        <td>
                            <span *ngIf="trip.date_of_Latest_Payment?.length > 0">{{trip.date_of_Latest_Payment}}</span>
                            <span *ngIf="!trip.date_of_Latest_Payment?.length > 0">.</span>
                        </td>
                        <td>
                            <span *ngIf="trip.settlement_Date?.length > 0">{{trip.settlement_Date}}</span>
                            <span *ngIf="!trip.settlement_Date?.length > 0">.</span>
                        </td>
                        <td class="textright">${{trip.total_Unprorated_Loss_Amount}}</td>
                    </tr>
                    <!-- if (tripitem == stateitem.Trip.Last())
                    {
                        <tr><td colspan=34>&nbsp;</td></tr>
                    } -->
                </ng-template>
                <tr><td colspan=34>&nbsp;</td></tr>
                <tr class='grid-total-row x96'>
                    <td colspan="7">State Total {{state.state_Code}}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>{{state.state_Total_Workers_Number}}</td>
                    <td>${{state.state_Total_Prior_Cum_Paid_Loss}}</td>
                    <td>${{state.state_Total_Current_Loss_Paid_Amount}}</td>
                    <td>${{state.state_Total_Current_Loss_To_Be_Paid_Amount}}</td>
                    <td>${{state.state_Total_Cumulative_Loss_Payments}}</td>
                    <td>${{state.state_Total_Punitive_Damages_Paid}}</td>
                    <td>${{state.state_Total_ALAE_Paid}}</td>
                    <td>${{state.state_Total_Salvage_Recovered}}</td>
                    <td>${{state.state_Total_Subrogation_Recovered}}</td>
                    <td>${{state.state_Total_Sal_and_Sub_Recovered}}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>${{state.state_Total_Dup_Fed_Comp_Amt_1}}</td>
                    <td>&nbsp;</td>
                    <td>${{state.state_Total_Dup_Fed_Comp_Amt_2}}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>${{state.state_Total_Case_Reserves}}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>${{state.state_Total_Unprorated_Loss_Amount}}</td>
                </tr>
            </ng-template>
            <tr class='grid-total-row x95'>
                <td colspan=7>LOB Total {{lob.loB_Code}}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>{{lob.lob_Total_Workers_Number}}</td>
                <td>${{lob.lob_Total_Prior_Cum_Paid_Loss}}</td>
                <td>${{lob.lob_Total_Current_Loss_Paid_Amount}}</td>
                <td>${{lob.lob_Total_Current_Loss_To_Be_Paid_Amount}}</td>
                <td>${{lob.lob_Total_Cumulative_Loss_Payments}}</td>
                <td>${{lob.lob_Total_Punitive_Damages_Paid}}</td>
                <td>${{lob.lob_Total_ALAE_Paid}}</td>
                <td>${{lob.lob_Total_Salvage_Recovered}}</td>
                <td>${{lob.lob_Total_Subrogation_Recovered}}</td>
                <td>${{lob.lob_Total_Sal_and_Sub_Recovered}}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>${{lob.lob_Total_Dup_Fed_Comp_Amt_1}}</td>
                <td>&nbsp;</td>
                <td>${{lob.lob_Total_Dup_Fed_Comp_Amt_2}}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>${{lob.lob_Total_Case_Reserves}}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>${{lob.lob_Total_Unprorated_Loss_Amount}}</td>
            </tr>
        </ng-template>
        <tr class='grid-total-row x94'>
            <td colspan=7>Catastrophe Total {{catastrophe.cat_Code}}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>{{catastrophe.cat_Total_Workers_Number}}</td>
            <td>${{catastrophe.cat_Total_Prior_Cum_Paid_Loss}}</td>
            <td>${{catastrophe.cat_Total_Current_Loss_Paid_Amount}}</td>
            <td>${{catastrophe.cat_Total_Current_Loss_To_Be_Paid_Amount}}</td>
            <td>${{catastrophe.cat_Total_Cumulative_Loss_Payments}}</td>
            <td>${{catastrophe.cat_Total_Punitive_Damages_Paid}}</td>
            <td>${{catastrophe.cat_Total_ALAE_Paid}}</td>
            <td>${{catastrophe.cat_Total_Salvage_Recovered}}</td>
            <td>${{catastrophe.cat_Total_Subrogation_Recovered}}</td>
            <td>${{catastrophe.cat_Total_Sal_and_Sub_Recovered}}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>${{catastrophe.cat_Total_Dup_Fed_Comp_Amt_1}}</td>
            <td>&nbsp;</td>
            <td>${{catastrophe.cat_Total_Dup_Fed_Comp_Amt_2}}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>${{catastrophe.cat_Total_Case_Reserves}}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>${{catastrophe.cat_Total_Unprorated_Loss_Amount}}</td>
        </tr>
    </ng-template>
    <tr><td colspan=34>&nbsp;</td></tr>
    <tr><td colspan=34>&nbsp;</td></tr>
    <tr class='grid-total-row x97'>
        <td colspan=7>GRAND TOTALS</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>{{schedulecReport.grand_Total_Workers_Number}}</td>
        <td>${{schedulecReport.grand_Total_Prior_Cum_Paid_Loss}}</td>
        <td>${{schedulecReport.grand_Total_Current_Loss_Paid_Amount}}</td>
        <td>${{schedulecReport.grand_Total_Current_Loss_To_Be_Paid_Amount}}</td>
        <td>${{schedulecReport.grand_Total_Cumulative_Loss_Payments}}</td>
        <td>${{schedulecReport.grand_Total_Punitive_Damages_Paid}}</td>
        <td>${{schedulecReport.grand_Total_ALAE_Paid}}</td>
        <td>${{schedulecReport.grand_Total_Salvage_Recovered}}</td>
        <td>${{schedulecReport.grand_Total_Subrogation_Recovered}}</td>
        <td>${{schedulecReport.grand_Total_Sal_and_Sub_Recovered}}</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>${{schedulecReport.grand_Total_Dup_Fed_Comp_Amt_1}}</td>
        <td>&nbsp;</td>
        <td>${{schedulecReport.grand_Total_Dup_Fed_Comp_Amt_2}}</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>${{schedulecReport.grand_Total_Case_Reserves}}</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>${{schedulecReport.grand_Total_Unprorated_Loss_Amount}}</td>
    </tr>
</table>
<div style="height: 60px;"></div>
</div>
