import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-excludingworkerscompensation',
  templateUrl: './excludingworkerscompensation.component.html',
  styleUrls: ['./excludingworkerscompensation.component.scss'],
})
export class ExcludingworkerscompensationComponent implements OnInit {
  calendarYear: any;
  calendarYearList: any = [];
  selectedCalendarYear: any;
  catastropheList: any = [];
  selectedCatastrophe: any;
  todayDate: any;
  selectedReportType: string;
  reportTypes: any = [
    { value: 'html', text: 'Html' },
    { value: 'excel', text: 'Excel' },
  ];
  excludingWCReport: any;
  showGrid: boolean = false;
  @ViewChild('asofDatePicker') selectedAsofDate: ElementRef;
  constructor(private reportSvc: ReportService) {}

  ngOnInit(): void {
    this.getCalendarYears();
  }
  getCalendarYears() {
    this.todayDate = new Date();
    this.selectedReportType = 'html';
    this.reportSvc.getExcludingWCCalendarYears().subscribe(
      (res) => {
        this.calendarYearList = res;
        this.selectedCalendarYear = this.calendarYearList[0];
        this.getCatastrophe();
      },
      () => {}
    );
  }
  getCatastrophe() {
    this.todayDate = new Date();
    this.selectedReportType = 'html';
    this.reportSvc.getExcludingWCCatastrophe().subscribe(
      (res) => {
        this.catastropheList = res;
        this.selectedCatastrophe = this.catastropheList[0];
      },
      () => {}
    );
  }
  onCalendarYearChange() {
    this.showGrid = false;
  }

  onAsOfDateChange() {
    this.showGrid = false;
  }
  onCatastropheChange() {
    this.showGrid = false;
  }
  generateReport() {
    if (this.selectedReportType === 'html') {
      this.getExcludingWCeport();
    } else if (this.selectedReportType === 'excel') {
      this.getFile();
    }
  }
  getExcludingWCeport() {
    this.reportSvc
      .getExcludingWCReport(
        this.selectedCalendarYear,
        this.selectedAsofDate.nativeElement.value,
        this.selectedCatastrophe
      )
      .subscribe(
        (res) => {
          this.excludingWCReport = res;
          this.showGrid = true;
        },
        () => {}
      );
  }

  getFile() {
    this.reportSvc
      .getExcludingWCFile(
        this.selectedCalendarYear,
        this.selectedAsofDate.nativeElement.value,
        this.selectedCatastrophe
      )
      .subscribe(
        (respData) => {
          this.downLoadFile(
            respData          );
        },
        () => {}
      );
  }
  downLoadFile(data: any) {
    let downloadLink = document.createElement('a');
    let filename: string = 'Excluding Workers Comp.xlsx';
    downloadLink.href = window.URL.createObjectURL(
      new Blob([data], { type: 'blob' })
    );
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
