import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ReportService } from '../report.service';
import { Observable } from 'rxjs';
import { TripAlertService } from '../trip-alert.service';

@Component({
  selector: 'app-paymentdisbursement',
  templateUrl: './paymentdisbursement.component.html',
  styleUrls: ['./paymentdisbursement.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PaymentdisbursementComponent implements OnInit {
  @ViewChild('paymentdisbursement') paymentdisbursement: ElementRef;

  selectedGroup: any;
  selectedCalendarYear: any;
  selectedIsoReceiptDate: any;
  paymentdisbursementReport: any;
  groupList: any = [];
  filterList: any = [];
  reportTypes: any = [
    { value: 'html', text: 'Html' },
    { value: 'pdf', text: 'PDF' },
  ];
  selectedReportType: string = 'html';

  groupNumber: string;
  calendarYear: any;
  isoReceiptDate: any;
  calendarYearList: any = [];
  isoReceiptDateList: any = [];
  showGrid: boolean = false;

  filteredGroups: Observable<string[]>;

  constructor(
    private reportSvc: ReportService,
    private tripAlert: TripAlertService,
    private ChangeRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getGroups();
  }

  onGroupChange() {
    this.selectedCalendarYear = '-1';
    this.isoReceiptDateList = [];
    this.showGrid = false;
    this.getFilterList();
  }
  onCalendarYearChange() {
    this.selectedIsoReceiptDate = '-1';
    this.showGrid = false;
    this.isoReceiptDateList = this.filterList.filter(
      (x) => x.calendarYear == this.selectedCalendarYear
    );
  }

  onIsoReceiptDateChange() {
    this.showGrid = false;
  }

  getGroups() {
    this.selectedCalendarYear = '-1';
    this.selectedIsoReceiptDate = '-1';
    this.showGrid = false;

    this.reportSvc.getPaymentDisbursementGroups().subscribe(
      (res) => {
        if (res) {
          this.groupList = res;
          this.selectedGroup = this.groupList[0].groupNumber;
          this.getFilterList();
        }
      },
      (error) => {}
    );
  }

  getFilterList() {
    this.reportSvc.getPaymentDisbursementFilters(this.selectedGroup).subscribe(
      (res) => {
        if (res) {
          this.filterList = res;
          this.calendarYearList = this.filterList
            .map((item) => item.calendarYear)
            .filter((value, index, self) => self.indexOf(value) === index);
        }
      },
      (error) => {}
    );
  }

  generateReport() {
    if (this.selectedCalendarYear === '-1') {
      this.tripAlert.alert('Please select  Calendar Year');
      return;
    }

    if (this.selectedIsoReceiptDate === '-1') {
      this.tripAlert.alert('Please select  ISO Receipt Date');
      return;
    }

    this.reportSvc
      .getPaymentDisbursementReport(
        this.selectedGroup,
        this.selectedCalendarYear,
        this.selectedIsoReceiptDate
      )
      .subscribe(
        (res) => {
          if (res) {
            this.paymentdisbursementReport = res;
            this.showGrid = true;
            if (this.selectedReportType === 'pdf') {
              this.ChangeRef.detectChanges();
              this.openPDF();
            }
          }
        },
        (error) => {}
      );
  }

  openPDF(): void {
    var tit = document.title;
    document.title = 'Payment Disbursement Information Ad Hoc';
    window.print();
    document.title = tit;
  }
}
