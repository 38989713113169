import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { Observable } from 'rxjs';
import { TripAlertService } from '../trip-alert.service';

@Component({
  selector: 'app-schedulec',
  templateUrl: './schedulec.component.html',
  styleUrls: ['./schedulec.component.scss'],
})
export class SchedulecComponent implements OnInit {
  selectedGroup: any;
  selectedCalendarYear: any;
  selectedIsoReceiptDate: any;
  schedulecReport: any;
  groupList: any = [];
  filterList: any = [];

  groupNumber: string;
  calendarYear: any;
  isoReceiptDate: any;
  calendarYearList: any = [];
  isoReceiptDateList: any = [];
  showGrid: boolean = false;
  reportTypes: any = [
    { value: 'html', text: 'Html' },
    { value: 'excel', text: 'Excel' },
  ];
  selectedReportType: string;

  filteredGroups: Observable<string[]>;
  constructor(
    private reportSvc: ReportService,
    private tripAlert: TripAlertService
  ) {}

  ngOnInit(): void {
    this.getGroups();
  }

  getGroups() {
    this.selectedCalendarYear = '-1';
    this.selectedIsoReceiptDate = '-1';
    this.selectedReportType = 'html';
    this.showGrid = false;
    
    this.reportSvc.getGroups().subscribe(
      (res) => {
        this.groupList = res;
        this.selectedGroup = this.groupList[0].groupNumber;
        this.getFilterList();
      },
      () => {}
    );
  }

  onGroupChange() {
    this.selectedCalendarYear = '-1';
    this.isoReceiptDateList = [];
    this.showGrid = false;
    this.getFilterList();
  }
  onCalendarYearChange() {
    this.selectedIsoReceiptDate = '-1';
    this.showGrid = false;
    this.isoReceiptDateList = this.filterList.filter(
      (x) => x.calendarYear == this.selectedCalendarYear
    );
  }
  onIsoReceiptDateChange() {
    //this.getScheduleCList();
    //this.generateReport();
    this.showGrid = false;
  }
  getFilterList() {
    this.reportSvc.getFilterData(this.selectedGroup).subscribe(
      (res) => {
        this.filterList = res;
        this.calendarYearList = this.filterList
          .map((item) => item.calendarYear)
          .filter((value, index, self) => self.indexOf(value) === index);
      },
      () => {}
    );
  }
  getScheduleCList() {
    this.reportSvc
      .getScheduleCReport(
        this.selectedGroup,
        this.selectedCalendarYear,
        this.selectedIsoReceiptDate
      )
      .subscribe(
        (res) => {
          this.schedulecReport = res;
          this.showGrid = true;
          
        },
        () => {}
      );
  }

  generateReport() {
    if (this.selectedCalendarYear === '-1') {
      this.tripAlert.alert('Please select  Calendar Year');
      return;
    }

    if (this.selectedIsoReceiptDate === '-1') {
      this.tripAlert.alert('Please select  ISO Receipt Date');
      return;
    }

    if (this.selectedReportType === 'html') {
      this.getScheduleCList();
    } else if (this.selectedReportType === 'excel') {
      this.getFile(
        this.selectedGroup,
        this.selectedCalendarYear,
        this.selectedIsoReceiptDate
      );
    }
  }

  getFile(groupNumber, calendarYear, isoReceiptDate) {
    this.reportSvc
      .getUploadedFile(groupNumber, calendarYear, isoReceiptDate)
      .subscribe(
        (respData) => {
          this.downLoadFile(respData);
        },
        () => {}
      );
  }

  downLoadFile(data: any) {
    let downloadLink = document.createElement('a');
    let filename: string = 'Loss Bordereau (Schedule C) Ad Hoc.xlsx';
    downloadLink.href = window.URL.createObjectURL(
      new Blob([data], { type: 'blob' })
    );
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
