
<div class="non-printable" *ngIf="AppAuthService.getLoginStatus()|async">
  <app-header (loggedOut)="logout()"></app-header>
</div>

<div class="lib-content" *ngIf="AppAuthService.getLoginStatus()|async">
    <div class="lib-container">

        <div class="lib-menu non-printable">
          <app-navigation></app-navigation>
        </div>
        <div class="lib-body">
          <router-outlet></router-outlet>
        </div>

    </div>
</div>

<div *ngIf="AppAuthService.getLoginStatus()|async" class="non-printable">
  <app-footer ></app-footer>
</div>

