<div class="rprtHeader non-printable">Payment Disbursement Information Ad Hoc</div>
<div class="rprtHeaderSub non-printable">Selection Criteria</div>

<div class="filterBox non-printable">


        <div class="inputcontainer">
            <label for="username">Group Name</label>
            <select id="filter-group" class="selectLongInput"  [(ngModel)]="selectedGroup" (change)="onGroupChange()" style="width: 135px;">
                <option *ngFor="let group of groupList" [value]="group.groupNumber">{{group.groupName}}</option>
            </select>
        </div>
        <div class="inputcontainer">
        <label for="username">Calendar Year</label>
            <select id="filter-calendar-year" class="selectShortInput" [(ngModel)]="selectedCalendarYear" (change)="onCalendarYearChange()">
                <option value="-1">--Select--</option>
                <option *ngFor="let calendarYear of calendarYearList" [value]="calendarYear">{{calendarYear}}</option>
            </select>
        </div>
        <div class="inputcontainer">
            <label for="username">ISO Receipt Date</label>
            <select id="filter-iso-recceipt-date"  class="selectInput" style="width: 146px !important;" [(ngModel)]="selectedIsoReceiptDate" (change)="onIsoReceiptDateChange()">
                <option value="-1">--Select--</option>
                <option *ngFor="let isoReceiptDateItem of isoReceiptDateList" [value]="isoReceiptDateItem.isoReceiptDate">{{isoReceiptDateItem.isoReceiptDate}}</option>
            </select>
        </div>

        <div class="inputcontainer">
          <label for="username">Format</label>
         <select [(ngModel)]="selectedReportType" class="selectShortInput1">
              <option *ngFor="let type of reportTypes" [value]="type.value">{{type.text}}</option>
          </select>
         </div>

     <button type="button" (click)="generateReport();" class="newButton rpt" >View Report</button>
</div>

<div class="tableWrapper" #paymentdisbursement style="height:400px;"  *ngIf="showGrid">

    <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt' class='x1'>
       
        <tr>
            <td style='padding-left:0pt' colspan=7>
                <table border=0 cellspacing=0 cellpadding=0 width="100%">
                    <tr>
                        <td class='x16'>Terrorism Risk Insurance Program</td>
                    </tr>
                    <tr class="lh-0_7">
                        <td class='x16'>Payment Disbursement Information Ad Hoc Detail</td>
                    </tr>
                    <tr class="lh-0_95">
                        <td class='x16'>&nbsp;</td>
                    </tr>
                    <tr>
                        <td align=CENTER>
                            <span class='x16'>Group: </span>
                            <span class='x16'>{{paymentdisbursementReport.groupName}}</span>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td class='x16'>&nbsp;</td>
                    </tr> -->
                    <tr class="lh-2_7">
                        <td align=CENTER>
                            <span class='x16'>ISO Receipt Date: </span>
                            <span class='x16'>{{selectedIsoReceiptDate | date : 'yyyy/MM/dd HH:mm:ss'}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class='x16'>&nbsp;</td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr style="line-height: 1.1">
            <td style="vertical-align:bottom" class='x9'><u>Calendar Year</u></td>
            <td style="vertical-align:bottom" class='x10'><u>Company or Group Number</u></td>
            <td style="vertical-align:bottom" class='x11'><u>Group Indicator Code</u></td>
            <td style="vertical-align:bottom" class='x12'><u>Disbursement Code</u></td>
            <td style="vertical-align:bottom" class='x13'><u>Payment Amount Requested</u></td>
            <td style="vertical-align:bottom" class='x14'><u>Payment Requested Date</u></td>
            <td style="vertical-align:bottom" class='x15 w-150'><u>Amount Approved by Treasury</u></td>
        </tr>
        <tr>
            <td class='x2_0'>{{paymentdisbursementReport.calendarYear}}</td>
            <td class='x3_0'>{{paymentdisbursementReport.companyorGroupNumber}}</td>
            <td class='x4_0'>{{paymentdisbursementReport.groupIndicatorCode}}</td>
            <td class='x5_0'>{{paymentdisbursementReport.disbursementCode}}</td>
            <td class='x6_0'>{{paymentdisbursementReport.paymentAmountRequested}}</td>
            <td class='x7_0'>{{paymentdisbursementReport.paymentRequestedDate}}</td>
            <td class='x8_0'>{{paymentdisbursementReport.amountApprovedbyTreasury}}</td>
        </tr>
    </table>

    <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt' class='x19'>
        <tr>
            <td colspan=6>&nbsp;</td>
        </tr>
        <tr>
            <td colspan=6>&nbsp;</td>
        </tr>
        <tr style="line-height: 1.1">
            <td style="vertical-align:bottom" class='x26'><u>Treasury Approval Date</u></td>
            <td style="vertical-align:bottom" class='x27'><u>Date Payment Made to Insurer</u></td>
            <td style="vertical-align:bottom" class='x28'><u>Treasury Control Number</u></td>
            <td style="vertical-align:bottom" class='x29'><u>Date Submitted by CPC</u></td>
            <td style="vertical-align:bottom" class='x30'><u>CPC Code</u></td>
            <td style="vertical-align:bottom" class='x31'><u>CPC Receipt Time</u></td>
        </tr>
        <tr>
            <td class='x20_0'>{{paymentdisbursementReport.treasuryApprovalDate}}</td>
            <td class='x21_0'>{{paymentdisbursementReport.datePaymenMadetoInsurer}}</td>
            <td class='x22_0'>{{paymentdisbursementReport.treasuryControlNumber}}</td>
            <td class='x23_0'>{{paymentdisbursementReport.dateSubmittedbyCPC | date : 'yyyy/MM/dd HH:mm:ss'}}</td>
            <td class='x24_0'>{{paymentdisbursementReport.cpcCode}}</td>
            <td class='x25_0'>{{paymentdisbursementReport.cpcReceiptTime | date : 'yyyy/MM/dd HH:mm:ss'}}</td>
        </tr>
        </table>

</div>
