import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AppConfig } from './appconfig.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  baseUrl= AppConfig.settings.apiUrl;
  constructor(private http: HttpClient) { }

  public getUserName(userId){
    let params = new HttpParams();
    params = params.append('userId', userId);
    return this.http.get(this.baseUrl+"api/userinfo/userinfo", {params:params} );
  }
}
