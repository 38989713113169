import { Component, OnInit } from '@angular/core';
import { ReportService} from '../report.service';
import { TripAlertService } from '../trip-alert.service';

@Component({
  selector: 'app-totalearnedpremium',
  templateUrl: './totalearnedpremium.component.html',
  styleUrls: ['./totalearnedpremium.component.scss']
})
export class TotalearnedpremiumComponent implements OnInit {

  calendarYearList:any;
  selectedCalendarYear : any;
  totalEarnedPremiumReport:any;

  reportTypes:any =[{value:'html', text:'Html'}, {value:'excel' , text: 'Excel'}];
  selectedReportType:any='html';
  showGrid:boolean = false;

  constructor(private reportService : ReportService, private alert:TripAlertService) { }

  ngOnInit(): void {
    this.getCalendarYears();
  }

  onCalendarYearChange()
  {
    this.showGrid=false;
  }

  generateReport(){

    if(this.selectedCalendarYear === "-1"){
      this.alert.alert("Please select Calendar Year");
      return;
    }

    if(this.selectedReportType === 'html'){
      this.reportService.getEarnedPremiumReport(this.selectedCalendarYear).subscribe((res) =>{
        if(res){
            this.totalEarnedPremiumReport=res;
            this.showGrid=true;
        }
      }, (error) => {

      });
    }
    else if(this.selectedReportType ==='excel'){
      this.getFile();
    }
  }

  getCalendarYears(){
    this.reportService.getEarnedPremiumCalendarYearList().subscribe((res) =>{

      if(res)
      {
        this.calendarYearList =res;
        this.selectedCalendarYear=res[0];
      }

    }, (error) => {

    });
  }

  getFile() {
    this.reportService.getEarnedPremiumExcel(this.selectedCalendarYear).subscribe(respData => {
        this.downLoadFile(respData, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    }, error => {

    });
}

  downLoadFile(data: any, type: string) {
    let downloadLink = document.createElement('a');
    let filename: string ="Total Earned Premiums for Individual Insurer Groups.xlsx";
    downloadLink.href = window.URL.createObjectURL(new Blob([data], { type: 'blob' }));
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
}

}
