<table cellpadding=1 style='margin-left:225px;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
    <tr> <td colspan=3> &nbsp;</td> </tr>
    <tr><td style='padding-left:0pt' colspan=3>
        <table border=0 cellspacing=0 cellpadding=0 width="100%">
            <tr>
                <td class='x11'> Loss Bordereau (Schedule C) Ad Hoc </td>
            </tr>
            <tr>
                <td class='x13'>&nbsp;</td>
            </tr>
            <tr>
                <td class='x15'>Group Number: {{groupNumber}}</td>
            </tr>
            <tr>
                <td class='x17'>&nbsp;</td>
            </tr>
        </table>
        </td>
    </tr>
    <tr>
        <td style="vertical-align:bottom" class='x9'><u>Calendar Year</u></td>
        <td style="vertical-align:bottom" class='x8'><u>ISO Receipt Date</u></td>
        <td style="vertical-align:bottom" class='x6'><u>Group Name</u></td>
    </tr>

    <tr *ngFor="let filter of filterList ; let i=index">
    <td [ngClass]="i % 2 === 0 ? 'x5_0' : 'x5_1'" >
    {{filter.calendarYear}}</td>
    <td [ngClass]="i % 2 === 0 ? 'x4_0' : 'x4_1'">
    <!-- <a [routerLink]="['/schedulec',groupNumber,filter.calendarYear,filter.isoReceiptDate]" >{{filter.isoReceiptDate}}</a> -->
    <a href="#" (click)="generateReport($event, groupNumber, filter.calendarYear, filter.isoReceiptDate)" >{{filter.isoReceiptDate}}</a>
    </td>
    <td [ngClass]="i % 2 === 0 ? 'x2_0' : 'x2_1'">
    {{filter.groupName}}</td>
    </tr>

</table>