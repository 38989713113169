import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from '../report.service';
import { Router } from '@angular/router';
import { group } from '@angular/animations';

@Component({
  selector: 'app-dateselection',
  templateUrl: './dateselection.component.html',
  styleUrls: ['./dateselection.component.scss']
})
export class DateselectionComponent implements OnInit {

  groupNumber:string;
  reportType:string;

  filterList:any =[];

  constructor(private activatedRoute: ActivatedRoute, private reportSvc : ReportService, private router: Router) { 
    activatedRoute.params.subscribe(params =>{
      this.groupNumber = params['group'];
      this.reportType= params['type'];
      console.log(this.groupNumber);
      console.log(this.reportType);
  });
  }

  ngOnInit(): void {
    this.getFilterList();
  }

  getFilterList(){
    this.reportSvc.getFilterData(this.groupNumber).subscribe((res) =>{
        this.filterList =res;
    },(error) => {

    });
  }

  generateReport(event, groupNumber, calendarYear, isoReceiptDate){
    event.preventDefault();
    if(this.reportType === "html"){
        this.router.navigate(['/schedulec',groupNumber,calendarYear,isoReceiptDate]);
    }else if(this.reportType === "excel"){
      this.getFile(groupNumber, calendarYear, isoReceiptDate);
    }
  }

  getFile(groupNumber, calendarYear, isoReceiptDate) {
    this.reportSvc.getUploadedFile(groupNumber, calendarYear, isoReceiptDate).subscribe(respData => {
        this.downLoadFile(respData, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    }, error => {

    });
}

  downLoadFile(data: any, type: string) {
    // var blob = new Blob([data], { type: type.toString() });
    // var url = window.URL.createObjectURL(blob);
    // var pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //     alert('Please disable your Pop-up blocker and try again.');
    // }
    let downloadLink = document.createElement('a');
    let filename: string ="schedule C.xlsx"
    downloadLink.href = window.URL.createObjectURL(new Blob([data], { type: 'blob' }));
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
}

}
