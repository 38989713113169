import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Router } from '@angular/router';

@Component({
  selector: 'app-groupselection',
  templateUrl: './groupselection.component.html',
  styleUrls: ['./groupselection.component.scss']
})
export class GroupselectionComponent implements OnInit {

  selectedGroup: any;
  selectedType: any ='html';
  groupList:any=[];
  reportTypes:any =[{value:'html', text:'Html'}, {value:'excel' , text: 'Excel'}];

  constructor(private reportSvc : ReportService, private router: Router) { }

  ngOnInit(): void {
    this.getGroups();
  }

  getGroups(){
    this.reportSvc.getGroups().subscribe((res) =>{

      this.groupList=res;
      this.selectedGroup =this.groupList[0].groupNumber;

    },(error) => {

    });
  }

   openPDF():void {
    let DATA = document.getElementById('htmlData');
        
    html2canvas(DATA).then(canvas => {
        
        let fileWidth = 208;
        let fileHeight = canvas.height * fileWidth / canvas.width;
        
        const FILEURI = canvas.toDataURL('image/png')
        let PDF = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
        
        PDF.save('angular-demo.pdf');
    });     
    }

   submit(){
     console.log(this.selectedGroup);
      this.router.navigate(['schedulec/year',this.selectedGroup,this.selectedType ]);
   }

}
