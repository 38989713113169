<div class="lib-footer">
  <footer class="footer">
    <div class="lib-foot-container">
      <div>
        <div class="lib-foot-logo">
          <div>
            <img
              src="../../assets/img/logo-one-verisk.png"
              alt=""
              height="24"
            />
          </div>
        </div>
        <div class="lib-foot-content">
          <p class="copyright">
            Copyright &copy; {{today_date | date:'yyyy'}} Insurance Services Office, Inc. All rights
            reserved.
          </p>

        </div>
      </div>
    </div>
  </footer>
</div>

