import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppAuthService {
  private isAuthenticated: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this.isAuthenticated = new BehaviorSubject(false);
  }

  getLoginStatus(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  setLoginStatus(status: boolean): void {
    this.isAuthenticated.next(status);
  }
}
