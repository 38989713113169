<div class="rprtHeader">Summary Based on Certification of Loss</div>
<div class="rprtHeaderSub">Selection Criteria</div>
<div class="filterBox">
    <div class="inputcontainer">
        <label for="calendarYear" >Calendar Year</label>
        <select id="filter-calendar-year" class="selectShortInput" (change)="onCalendarYearChange();" [(ngModel)]="selectedCalendarYear" >
            <option *ngFor="let item of calendarYearList" [value]="item.calendarYear">{{item.calendarYear}}</option>
        </select>
    </div>
    <div class="inputcontainer">
        <label for="asofDate">As of Date</label>
        <input type="date" onkeydown="return false;" id="asofdateId" style="font-size:12px;height:29px;" (change)="onAsOfDateChange();" class="dateInput" [ngModel]="todayDate | date:'yyyy-MM-dd'" #asofDatePicker>
        
    </div>
  
    <div class="inputcontainer">
        <label for="username" >Format</label>
       <select [(ngModel)]="selectedReportType" class="selectShortInput">
            <option *ngFor="let type of reportTypes" [value]="type.value">{{type.text}}</option>
        </select>
    </div>
 
 <button type="button" (click)="generateReport();" class="newButton rpt" >View Report</button>

</div>
<script>
    
</script>
<div id ="loss-summary-report-id" class="tableWrapper"  *ngIf="showGrid">
    <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
    <tr>
        <td colspan=7 class='x1'>
            <!-- &nbsp; -->
        </td>
    </tr>
    <tr>
        <td style='padding-left:0pt' colspan=7 class='x23'>
            <table border=0 cellspacing=0 cellpadding=0 width="100%">
                <tr>
                    <td class='x23'>
                        <!-- &nbsp; -->
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    <tr>
        <td style='padding-left:0pt' colspan=7 class='x1'>
            <table border=0 cellspacing=0 cellpadding=0 width="100%">
                <tr>
                    <td class='x25'>
                        Terrorism Risk Insurance Program </td>
                </tr>
                <tr>
                    <td class='x26'>
                        Summary Based on Certification of Loss</td>
                </tr>
                <tr style="line-height: 1;">
                    <td>
                        &nbsp;</td>
                </tr>
                <tr>
                    <td class='x27'>
                        Calendar Year: {{selectedCalendarYear}} As of Date: {{selectedAsofDate.nativeElement.value | date : 'MM/dd/yyyy'}}</td>
                </tr>
                <tr>
                    <td>
                        &nbsp;</td>
                </tr>
            </table>
        </td>
    </tr>
    <tr>
        <td style="vertical-align:bottom" class='x13'>
            <u>Insurer Group Number</u></td>
        <td style="vertical-align:bottom" class='x14'>
            <u>Insurer Group Name</u></td>
        <td style="vertical-align:bottom" class='x20'>
            <u>Adjusted Insured Loss Paid</u></td>
        <td style="vertical-align:bottom" class='x21'>
            <u>Insurer Deductible</u></td>
        <td style="vertical-align:bottom" class='x15'>
            <u>Total Net Federal Share Claimed</u></td>
        <td style="vertical-align:bottom" class='x16'>
            <u>Prior Federal Share Claimed</u></td>
        <td style="vertical-align:bottom" class='x17'>
            <u>Current Federal Share Due</u></td>
    </tr>
    <ng-template ngFor let-loss [ngForOf]="losses"; let-i="index">
        <tr class="grid-data-row">
            <td>
                {{loss.insurerGroupNumber}}</td>
            <td>
                {{loss.insurerGroupName}}</td>
            <td class="align-right">
                {{loss.adjustedInsuredLossPaid}}</td>
            <td class="align-right">
                {{loss.insurerDeductible}}</td>
            <td class="align-right">
                {{loss.totalNetFederalShareClaimed}}</td>
            <td class="align-right">
                {{loss.priorFederalShareClaimed}}</td>
            <td NOWRAP class="align-right">
                {{loss.currentFederalShareDue}}</td>
        </tr>
    </ng-template>
    <tr>
        <td colspan=7 class='x1'>
            &nbsp;</td>
    </tr>
    <tr style="line-height: 1.1;">
        <td class='x31'>
            *TOTAL {{lossSummaryReport.calenderYear}}</td>
        <td class='x31'>
            &nbsp;</td>
        <td align=RIGHT class='x31'>
            {{lossSummaryReport.adjustedInsuredLossPaid}}</td>
        <td align=RIGHT class='x31'>
            {{lossSummaryReport.insurerDeductible}}</td>
        <td align=RIGHT class='x31'>
            {{lossSummaryReport.totalNetFederalShareClaimed}}</td>
        <td align=RIGHT class='x31'>
            {{lossSummaryReport.priorFederalShareClaimed}}</td>
        <td NOWRAP align=RIGHT class='x31'>
            {{lossSummaryReport.currentFederalShareDue}}</td>
    </tr>
    <tr>
        <td colspan=7 class='x1'>
            &nbsp;</td>
    </tr>
</table>
<div style="height: 60px;"></div>
</div>
