<div class="rprtHeader">Loss Bordereau for Workers Compensation (including Residual Market Data)</div>
<div class="rprtHeaderSub">Selection Criteria</div>
<div class="filterBox">
        <div class="inputcontainer">
            <label for="calendar">Calendar Year</label>
            <select id="filter-calendar-year" class="selectShortInput" (change)="onCalendarYearChange();" [(ngModel)]="selectedCalendarYear">
                <option *ngFor="let calendarYear of calendarYearList" [value]="calendarYear">{{calendarYear}}</option>
            </select>
        </div>
        <div class="inputcontainer">
            <label for="asofDate" >As of Date</label>
            <input type="date" onkeydown="return false;" id="asofdateId" style="font-size:12px;height:29px;" class="dateInput" (change)="onAsOfDateChange();" [ngModel]="todayDate | date:'yyyy-MM-dd'" #asofDatePicker>
            
        </div>
        <div class="inputcontainer">
            <label for="catastrophe" >Catastrophe Code</label>
            <select id="filter-catastrophe" class="selectShortInput" (change)="onCatastropheChange();" [(ngModel)]="selectedCatastrophe">
                <option *ngFor="let catastrophe of catastropheList" [value]="catastrophe">{{catastrophe}}</option>
            </select>
        </div>
        <div class="inputcontainer">
            <label for="output">Format</label>
           <select [(ngModel)]="selectedReportType" class="selectShortInput">
                <option *ngFor="let type of reportTypes" [value]="type.value">{{type.text}}</option>
            </select>
        </div>
     
     <button type="button" (click)="generateReport();" class="newButton rpt" >View Report</button>

</div>
<div  *ngIf="showGrid" class="tableWrapper">
    <table class="header-box" border=0 cellspacing=0 cellpadding=0 width="100%">
        <tr>
            <td class='x70'>
                Terrorism Risk Insurance Program
            </td>
        </tr>
        <tr>
            <td class='x70'>
                Information on Loss Amounts from Bordereau
            </td>
        </tr>
        <tr>
            <td class='x70'>Workers Compensation (including Residual Market Data)</td>
        </tr>
        <tr>
            <td>
            &nbsp;</td>
            </tr>
        <tr>
            <td class='x70'>
                Calendar Year: {{selectedCalendarYear}}  &nbsp;&nbsp;  As of Date: {{selectedAsofDate.nativeElement.value | date : "MM/dd/yyyy"}}</td>
        </tr>
        
    </table>
    <div id ="WorkersCompensation-report-id">
    <table class="w-135" cellpadding=1 style='padding-right: 10px; margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
       
        <tr>
            <td style='padding-left:0pt' colspan=34>
                
            </td>
        </tr>
        <tr>
            <td style="vertical-align:bottom" class='x14'>
                <u>Catastrophe Code</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>State</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>Insurer Group No.</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>WC Indicator</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>II Count</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>Number of WC Claimants</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>Total Cumulative Loss Payments</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>Total Punitive Damages</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>Total ALAE Paid</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>Ratio of ALAE to TRIA Loss</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>Total Salvage and Subrogation Recovered</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>Duplicate Federal Compensation</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>Case Reserves</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>Net TRIA Insured Losses</u></td>
            <td style="vertical-align:bottom" class='x14'>
                <u>Average Per Claimant</u></td>
        </tr>
        <ng-template ngFor let-state [ngForOf]="workersCompensationReport.workersCompStates"; let-stateIndex="index">
                    <ng-template ngFor let-group [ngForOf]="state.workersCompGroups"; let-groupIndex="index">
                        <tr *ngIf="!(stateIndex == 0 && groupIndex == 0)"  style="line-height: 1;"><td>&nbsp;</td></tr>
                        <ng-template ngFor let-item [ngForOf]="group.workersCompDetails"; let-itemIndex="index" >
                            
                    <tr class="grid-data-row row_{{item.reportRowNumber % 2}}">
                        <td>
                            {{item.catastropheCode}}</td>
                        <td>
                            {{item.stateCode}}</td>
                        <td>
                            {{item.insurerGroupNo}}</td>
                        <td>
                            {{item.wcIndicator}}</td>
                        <td class="align-right">
                            {{item.wcCount}}</td>
                        <td class="align-right">
                            {{item.numberOfWCClaimants}}</td>
                        <td class="align-right">
                            {{item.totalCumulativeLossPayments}}</td>
                        <td class="align-right">
                            {{item.totalPunitiveDamages}}</td>
                        <td class="align-right">
                            {{item.totalALAEPaid}}</td>
                        <td class="align-right">
                            {{item.ratioOfALAEToTRIALoss}}</td>
                        <td class="align-right">
                            {{item.totalSalvageAndSubrogationRecovered}}</td>
                        <td class="align-right">
                            {{item.duplicateFederalCompensation}}</td>
                        <td class="align-right">
                            {{item.caseReserves}}</td>
                        <td class="align-right">
                            {{item.netTriaLoss}}</td>
                        <td class="align-right">
                            {{item.averagePerClaimant}}</td>
                        </tr>
                        
                    </ng-template>
                    <tr style="line-height: 1;"><td>&nbsp;</td></tr>
                    <tr class="lob-total" style="line-height: 1.2;">
                        <td colspan=4 class='x27'>
                        Group Total {{group.insurerGroupNo}}</td>
                        <td class='x27'>
                            {{group.totalWCCount}}</td>
                        <td class='x27'>
                            {{group.totalNumberOfWCClaimants}}</td>
                        <td class='x27'>
                            {{group.totalCumulativeLossPayments}}</td>
                        <td class='x27'>
                            {{group.totalPunitiveDamages}}</td>
                        <td class='x27'>
                            {{group.totalALAEPaid}}</td>
                        <td class='x27'>
                            {{group.totalRatioOfALAEToTRIALoss}}</td>
                        <td class='x27'>
                            {{group.totalSalvageAndSubrogationRecovered}}</td>
                        <td class='x27'>
                            {{group.totalDuplicateFederalCompensation}}</td>
                        <td class='x27'>
                            {{group.totalCaseReserves}}</td>
                        <td class='x27'>
                            {{group.totalNetTriaLoss}}</td>
                        <td class='x27'>
                            {{group.totalAveragePerClaimant}}</td>
                        </tr>
                    </ng-template>
                    <tr style="line-height: 1.2;">
                        <td colspan=4 class='x27'>
                        State Total {{state.stateCode}}</td>
                        <td class='x27'>
                            {{state.totalWCCount}}</td>
                        <td class='x27'>
                            {{state.totalNumberOfWCClaimants}}</td>
                        <td class='x27'>
                            {{state.totalCumulativeLossPayments}}</td>
                        <td class='x27'>
                            {{state.totalPunitiveDamages}}</td>
                        <td class='x27'>
                            {{state.totalALAEPaid}}</td>
                        <td class='x27'>
                            {{state.totalRatioOfALAEToTRIALoss}}</td>
                        <td class='x27'>
                            {{state.totalSalvageAndSubrogationRecovered}}</td>
                        <td class='x27'>
                            {{state.totalDuplicateFederalCompensation}}</td>
                        <td class='x27'>
                            {{state.totalCaseReserves}}</td>
                        <td class='x27'>
                            {{state.totalNetTriaLoss}}</td>
                        <td class='x27'>
                            {{state.totalAveragePerClaimant}}</td>
                        </tr>
                </ng-template>
                <tr *ngIf="workersCompensationReport.workersCompStates.length > 0" style="line-height: 1.1;">
                    <td colspan=4 class='x27'>
                        Catastrophe Total {{workersCompensationReport.catastropheCode}}</td>
                        <td class='x27'>
                            {{workersCompensationReport.totalWCCount}}</td>
                        <td class='x27'>
                            {{workersCompensationReport.totalNumberOfWCClaimants}}</td>
                        <td class='x27'>
                            {{workersCompensationReport.totalCumulativeLossPayments}}</td>
                        <td class='x27'>
                            {{workersCompensationReport.totalPunitiveDamages}}</td>
                        <td class='x27'>
                            {{workersCompensationReport.totalALAEPaid}}</td>
                        <td class='x27'>
                            {{workersCompensationReport.totalRatioOfALAEToTRIALoss}}</td>
                        <td class='x27'>
                            {{workersCompensationReport.totalSalvageAndSubrogationRecovered}}</td>
                        <td class='x27'>
                            {{workersCompensationReport.totalDuplicateFederalCompensation}}</td>
                        <td class='x27'>
                            {{workersCompensationReport.totalCaseReserves}}</td>
                        <td class='x27'>
                            {{workersCompensationReport.totalNetTriaLoss}}</td>
                        <td class='x27'>
                            {{workersCompensationReport.totalAveragePerClaimant}}</td>
                    </tr>
            
    </table>
    </div>
</div>
