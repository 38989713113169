import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { TokenService} from '../token.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() loggedOut:EventEmitter<any> = new EventEmitter();

  showingProfile:boolean = false;

  userName:string="";

  constructor(private tokenSvc : TokenService) { }

  ngOnInit(): void {
    this.getUserName();
  }

  getUserName(){
    var userId =this.getCookie('userid');
    var ticket = this.getCookie('ticket');
    if(userId === null || ticket === null)
    {
      return;
    }

    this.tokenSvc.getUserName(userId).subscribe((res:any) =>{
      this.userName = res.fullName;
      });  
  }

  getCookie(name: string): string {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(';')
        .map((c) => c.trim())
        .filter((cookie) => {
          return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map((cookie) => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null
    );
  }

}
