import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { TripAlertService } from './trip-alert.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private tripAlert: TripAlertService,
    private oauthService: OAuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.status == 500) {
          this.tripAlert.alert('Something went wrong');
        }
        if (error.status == 403 || error.status == 401) {
          this.tripAlert
            .alert('You are not authorized for this action ')
            .then((_) => {
              this.oauthService.logOut();
            });
        }
        return throwError(errorMessage);
      })
    ) as Observable<HttpEvent<any>>;
  }
}
