import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { AppConfig } from './appconfig.service';
import { AppAuthService } from './auth/app-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'TripOnlineClient';
  authConfig: AuthConfig = { waitForTokenInMsec: 10 };
  ApplicationUrl: string;
  errorMessage: any;
  isAuthenticated = false;

  constructor(
    private oauthService: OAuthService,
    public AppAuthService: AppAuthService,
    private router: Router
  ) {
    const appConfig = AppConfig.settings;
    this.authConfig.issuer = appConfig.identityUrl;
    this.authConfig.redirectUri = appConfig.redirectUrl + '?domainMigrated=true';
    console.log('before authConfig.redirectUri >> ' + this.authConfig.redirectUri);
    this.authConfig.clientId = appConfig.clientId;
    this.authConfig.requireHttps = false;
    this.authConfig.scope = 'openid profile email';
    this.authConfig.logoutUrl = appConfig.identityUrl + '/connect/endsession';
    this.authConfig.postLogoutRedirectUri=appConfig.identityUrl;
    this.configureWithConfigApi();
    this.geToken(appConfig.apiUrl);
    this.ApplicationUrl = appConfig.apiUrl;

    this.router.events.subscribe((routerEvent: any) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  ngOnInit() {}

  geToken(apiUrl: string): void {
    if(window.location.href.toLowerCase().indexOf('.iso.com') >= 0){      
      let url= window.location.href.replace('.iso.com','.verisk.com');                                                                                  
      window.location.href=url;
    }
    this.AppAuthService.getLoginStatus().subscribe((status) => {
      this.isAuthenticated = status;
      if (status) {
        const appConfig = AppConfig.settings;
        this.authConfig.redirectUri = appConfig.redirectUrl + '?domainMigrated=true';
        console.log('after authConfig.redirectUri >> ' + this.authConfig.redirectUri);
      }
      // if (status) {
      //   const appConfig = this.AppAuthService.getGlobalConfig();
        //location.href=appConfig.apiUrl;
        //location.href='/dboard';
      // }
    });
  }

  getCookie(name: string) {
    return sessionStorage.getItem(name);
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationEnd) {
      if (this.getCookie('id_token')) {
        this.AppAuthService.setLoginStatus(true);
      }
    }
  }

  private configureWithConfigApi() {
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.configure(this.authConfig);
    this.oauthService.requireHttps = false;
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  logout() {
    sessionStorage.clear();
    // this.oauthService.setStorage(sessionStorage);
    
    this.deleteCookie('ticket');
    this.deleteCookie('customername');
    this.deleteCookie('cstid');
    this.deleteCookie('userid');
    this.deleteCookie('timestamp');
    this.deleteCookie('profileselected');
    this.deleteCookie('auth_type');
    this.deleteCookie('prdid');
    this.AppAuthService.setLoginStatus(false);
    this.oauthService.logOut();
  }

  deleteCookie(cookieName) {
    document.cookie = cookieName + '=;domain=iso.com;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/; secure';
    document.cookie = cookieName + '=;domain=verisk.com;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/; secure';
  }
}
