import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as pbi from 'powerbi-client';
import { TokenService } from '../token.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  report: pbi.Embed;

  reportId: string = '';

  @ViewChild('reportContainer', { static: false }) reportContainer: ElementRef;

  constructor(
    private tokenSvc: TokenService,
    activatedRoute: ActivatedRoute
  ) {
    activatedRoute.params.subscribe((params) => {
      this.reportId = params['reportid'];
      console.log(this.reportId);
      this.getEmdebReport();
    });
  }

  ngOnInit(): void {}

  printReport() {}

  getEmdebReport() {
    //this.reportContainer.nativeElement.remove();
    // this.tokenSvc.getEmbedToken(this.reportId).subscribe(
    //   (res) => {
    //     var embedObj = res;
    //     this.showReport(embedObj);
    //   },
    //   () => {}
    // );
  }

  showReport(embedObj) {
    // Embed URL
    let embedUrl = embedObj.EmbedReport[0].EmbedUrl;
    let embedReportId = embedObj.EmbedReport[0].ReportId;
    let settings: pbi.IEmbedSettings = {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    };
    let config: pbi.IEmbedConfiguration = {
      type: 'report',
      tokenType: pbi.models.TokenType.Embed,
      accessToken: embedObj.EmbedToken.Token,
      embedUrl: embedUrl,
      id: embedReportId,
      filters: [],
      settings: settings,
    };
    let powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    powerbi.reset(this.reportContainer.nativeElement);
    let reportContainer = this.reportContainer.nativeElement;

    this.report = powerbi.embed(reportContainer, config);
    this.report.off('loaded');
    this.report.on('loaded', () => {
      console.log('Loaded');
      //this.setTokenExpirationListener(Token.expiration, 2);
    });
    this.report.on('error', () => {
      console.log('Error');
    });
  }
}
