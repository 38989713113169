import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ReportService } from '../report.service';
import { TripAlertService } from '../trip-alert.service';

@Component({
  selector: 'app-noticeofdeductible',
  templateUrl: './noticeofdeductible.component.html',
  styleUrls: ['./noticeofdeductible.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NoticeofdeductibleComponent implements OnInit {
  selectedGroup: any;
  selectedCalendarYear: any;
  selectedIsoReceiptDate: any;
  noticeofDductibleReport: any;
  groupList: any = [];
  calendarYearList: any = [];
  isoReceiptDateList: any = [];
  reportTypes: any = [
    { value: 'html', text: 'Html' },
    { value: 'pdf', text: 'PDF' },
  ];
  selectedReportType: string = 'html';

  showGrid: boolean = false;
  constructor(
    private reportSvc: ReportService,
    private alert: TripAlertService,
    private ChangeRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getGroups();
  }
  getGroups() {
    this.selectedCalendarYear = '-1';
    this.selectedIsoReceiptDate = '-1';
    this.showGrid = false;
    this.reportSvc.getNoticeofDeductibleGroups().subscribe(
      (res) => {
        if (res) {
          this.groupList = res;
          this.selectedGroup = this.groupList[0].groupNumber;
          this.getCalendarYear();
        }
      },
      () => {}
    );
  }
  onGroupChange() {
    this.selectedCalendarYear = '-1';
    this.isoReceiptDateList = [];
    this.showGrid = false;
    this.getCalendarYear();
  }
  getCalendarYear() {
    this.reportSvc
      .getNoticeofDeductibleCalendarYear(this.selectedGroup)
      .subscribe(
        (res) => {
          if (res) {
            this.calendarYearList = res;
          }
        },
        () => {}
      );
  }
  onCalendarYearChange() {
    this.selectedIsoReceiptDate = '-1';
    this.showGrid = false;
    this.getIsoReceiptDate();
  }
  getIsoReceiptDate() {
    this.reportSvc
      .getNoticeofDeductibleIsoReceiptDate(
        this.selectedGroup,
        this.selectedCalendarYear
      )
      .subscribe(
        (res) => {
          if (res) {
            this.isoReceiptDateList = res;
          }
        },
        () => {}
      );
  }
  onIsoReceiptDateChange() {
    this.showGrid = false;
  }

  generateReport() {
    if (this.selectedCalendarYear === '-1') {
      this.alert.alert('Please select  Calendar Year');
      return;
    }

    if (this.selectedIsoReceiptDate === '-1') {
      this.alert.alert('Please select  ISO Receipt Date');
      return;
    }

    this.getNoticeofDeductibleReport();
  }

  generatePdf() {
    var _title = document.title;
    document.title = 'Notice of Deductible Errosion Ad Hoc Summary';
    window.print();
    document.title = _title; // this.showGrid=false;
  }

  getNoticeofDeductibleReport() {
    this.reportSvc
      .getNoticeofDeductibleReport(
        this.selectedGroup,
        this.selectedCalendarYear,
        this.selectedIsoReceiptDate
      )
      .subscribe(
        (res) => {
          if (res) {
            this.noticeofDductibleReport = res;
            this.showGrid = true;
            if (this.selectedReportType === 'pdf') {
              this.ChangeRef.detectChanges();
              this.generatePdf();
            }
          }
        },
        () => {}
      );
  }
}
