<div class="rprtHeader">Total Earned Premiums for Individual Insurer Groups</div>
<div class="rprtHeaderSub">Selection Criteria</div>

<div class="filterBox"> 
        <div class="inputcontainer">
        <label for="username">Calendar Year</label>
            <select id="filter-calendar-year" class="selectShortInput" [(ngModel)]="selectedCalendarYear" (change)="onCalendarYearChange()">
                <option value="-1">--Select--</option>
                <option *ngFor="let calendarYear of calendarYearList" [value]="calendarYear">{{calendarYear}}</option>
            </select>
        </div>
        <div class="inputcontainer">
            <label for="username">Format</label>
           <select [(ngModel)]="selectedReportType" class="selectShortInput1">
                <option *ngFor="let type of reportTypes" [value]="type.value">{{type.text}}</option>
            </select>
        </div>
     <button type="button" (click)="generateReport();" class="newButton rpt" >View Report</button>
</div>

<div class="tableWrapper" *ngIf="showGrid" >
    <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
        <!-- <tr>
            <td colspan=9>&nbsp;</td>
        </tr> -->
        <!-- <tr>
            <td style='padding-left:0pt' colspan=9>
                <table border=0 cellspacing=0 cellpadding=0 width="100%">
                    <tr>
                        <td class='x22'>&nbsp;</td>
                    </tr>
                </table>
            </td>
        </tr> -->
        <tr>
            <td style='padding-left:0pt' colspan=9>
                <table border=0 cellspacing=0 cellpadding=0 width="100%">
                    <tr>
                        <td class='x25'>Terrorism Risk Insurance Program </td>
                    </tr>
                    <tr>
                        <td class='x27'>Total Earned Premiums for Individual Insurer Groups</td>
                    </tr>
                    <tr><td>&nbsp;</td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr class="lh-1_2">
            <td style="vertical-align:bottom; width:70px;" class='x21'><u>Calendar Year</u></td>
            <td style="vertical-align:bottom; width:110px;" class='x13'><u>Company or Group Number</u></td>
            <td style="vertical-align:bottom; width:150px;" class='x12'><u>Insurer Group Name</u></td>
            <td style="vertical-align:bottom; width:140px;" class='x14'><u>Total Direct Earned Premium (Step 1)</u></td>
            <td style="vertical-align:bottom; width:125px;" class='x15'><u>Total Premium Excluded for TRIA (Step 2)</u></td>
            <td style="vertical-align:bottom; width:160px;" class='x16'><u>Total Premium Ceded to Residual Markets (Step 3)</u></td>
            <td style="vertical-align:bottom; width:160px;" class='x17'><u>Total Premium Received from Residual Markets (Step 4)</u></td>
            <td style="vertical-align:bottom; width:120px;" class='x18'><u>TRIA Direct Earned Premium</u></td>
            <td style="vertical-align:bottom;" class='x19'><u>Insurer Deductible (Step 5)</u></td>
        </tr>
        <tr *ngFor="let premium of totalEarnedPremiumReport.earnedPremiumList; let i = index">
            <td [ngClass]="{ 'x11_0' : i % 2==0, 'x11_1' : i % 2 !=0 }" > {{i == 0 ? totalEarnedPremiumReport.calendarYear : ''}}</td>
            <td [ngClass]="{ 'x3_0' : i % 2==0, 'x3_1' : i % 2 !=0 }" class='x3_0'>{{premium.companyOrGroupNumber}}</td>
            <td [ngClass]="{ 'x2_0' : i % 2==0, 'x2_1' : i % 2 !=0 }" class='x2_0'>{{premium.insurerGroupName}}</td>
            <td [ngClass]="{ 'x4_0' : i % 2==0, 'x4_1' : i % 2 !=0 }" class='x4_0'>{{premium.directEarnedPremium}}</td>
            <td [ngClass]="{ 'x5_0' : i % 2==0, 'x5_1' : i % 2 !=0 }" class='x5_0'>{{premium.premiumExcludedForTRIA}}</td>
            <td [ngClass]="{ 'x6_0' : i % 2==0, 'x6_1' : i % 2 !=0 }" class='x6_0'>{{premium.premiumCededResidualMarkets}}</td>
            <td [ngClass]="{ 'x7_0' : i % 2==0, 'x7_1' : i % 2 !=0 }" class='x7_0'>{{premium.premiumReceivedResidualMarkets}}</td>
            <td [ngClass]="{ 'x8_0' : i % 2==0, 'x8_1' : i % 2 !=0 }" class='x8_0'>{{premium.triaDirectEarnedPremium}}</td>
            <td [ngClass]="{ 'x9_0' : i % 2==0, 'x9_1' : i % 2 !=0 }" class='x9_0'>{{premium.insurerDeductible}}</td>
        </tr>
        <tr>
        <tr>
            <td colspan=9>&nbsp;</td>
        </tr>
        <tr>
            <td colspan=2 class='x28'>*TOTAL {{selectedCalendarYear}}</td>
            <td class='x28'>&nbsp;</td>
            <td align=RIGHT class='x28'>{{totalEarnedPremiumReport.totalDirectEarnedPremium}}</td>
            <td align=RIGHT class='x28'>{{totalEarnedPremiumReport.totalPremiumExcludedForTRIA}}</td>
            <td align=RIGHT class='x28'>{{totalEarnedPremiumReport.totalPremiumCededResidualMarkets}}</td>
            <td align=RIGHT class='x28'>{{totalEarnedPremiumReport.totalPremiumReceivedResidualMarkets}}</td>
            <td align=RIGHT class='x28'>{{totalEarnedPremiumReport.totalTRIADirectEarnedPremium}}</td>
            <td align=RIGHT class='x28'>{{totalEarnedPremiumReport.totalInsurerDeductible}}</td>
        </tr>
        <tr>
            <td colspan=9>&nbsp;</td>
        </tr>
    </table>
    
</div>