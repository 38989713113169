import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { ReportComponent } from './report/report.component';
import { TokenService } from './token.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTreeModule } from '@angular/material/tree';
import { TreeviewComponent } from './treeview/treeview.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { GroupselectionComponent } from './schedulec/groupselection.component';
import { ReportService } from './report.service';
import { DateselectionComponent } from './schedulec/dateselection.component';
import { SchedulecComponent } from './schedulec/schedulec.component';
import { CertificateofcomplianceComponent } from './certificateofcompliance/certificateofcompliance.component';
import { CertificationoflosssummaryComponent } from './certificationofloss/certificationoflosssummary/certificationoflosssummary.component';
//import { ScheduleaComponent } from './schedulea/schedulea.component';
import { AppConfig } from './appconfig.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth/auth.guard';
import { ExcludingworkerscompensationComponent } from './excludingworkerscompensation/excludingworkerscompensation.component'
import { ScheduleaComponent } from './schedulea/schedulea.component';
import { NoticeofdeductibleComponent } from './noticeofdeductible/noticeofdeductible.component';
import { TotalearnedpremiumComponent } from './totalearnedpremium/totalearnedpremium.component';
import { ResidualMarketDataComponent } from './residual-market-data/residual-market-data.component';
import { CertificationoflossadhocComponent } from './certificationofloss/certificationoflossadhoc/certificationoflossadhoc.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { PaymentdisbursementComponent } from './paymentdisbursement/paymentdisbursement.component';
import { WorkerscompensationComponent } from './workerscompensation/workerscompensation.component';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    ReportComponent,
    TreeviewComponent,
    GroupselectionComponent,
    DateselectionComponent,
    SchedulecComponent,
    CertificateofcomplianceComponent,
    CertificationoflosssummaryComponent,
    HomeComponent,
    //ScheduleaComponent
    ExcludingworkerscompensationComponent,
    ScheduleaComponent,
    NoticeofdeductibleComponent,
    TotalearnedpremiumComponent,
    ResidualMarketDataComponent,
    CertificationoflossadhocComponent,
    PaymentdisbursementComponent,
    WorkerscompensationComponent



  ],
  exports: [],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    TokenService,
    ReportService,
    AppConfig,
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:AuthInterceptor,
      multi:true
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:ErrorInterceptor,
      multi:true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
