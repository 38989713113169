import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportComponent } from './report/report.component';
import { GroupselectionComponent } from './schedulec/groupselection.component';
import {DateselectionComponent } from './schedulec/dateselection.component';
import {SchedulecComponent} from './schedulec/schedulec.component';
import { CertificateofcomplianceComponent } from './certificateofcompliance/certificateofcompliance.component';
import { CertificationoflosssummaryComponent } from './certificationofloss/certificationoflosssummary/certificationoflosssummary.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth/auth.guard';
import { ExcludingworkerscompensationComponent } from './excludingworkerscompensation/excludingworkerscompensation.component';
import { ScheduleaComponent} from './schedulea/schedulea.component';
import { NoticeofdeductibleComponent } from './noticeofdeductible/noticeofdeductible.component';
import { TotalearnedpremiumComponent} from './totalearnedpremium/totalearnedpremium.component';
import { ResidualMarketDataComponent } from './residual-market-data/residual-market-data.component';
import { CertificationoflossadhocComponent } from './certificationofloss/certificationoflossadhoc/certificationoflossadhoc.component';
import { PaymentdisbursementComponent} from './paymentdisbursement/paymentdisbursement.component';
import { WorkerscompensationComponent } from './workerscompensation/workerscompensation.component';

const routes: Routes = [
//{path: 'report/:reportid', component:ReportComponent },
//{path:'schedulec/group', component:GroupselectionComponent},
//{path: 'schedulec/year/:group/:type',component:  DateselectionComponent},
{ path: '', redirectTo:'home', pathMatch: 'full'},
{ path: 'home', component:HomeComponent, pathMatch: 'full'},
{path: 'earnedpremium', component:TotalearnedpremiumComponent, canActivate:[AuthGuard]},
{path: 'residualMarketData', component:ResidualMarketDataComponent, canActivate:[AuthGuard]},
{path: 'certoflossadhoc', component: CertificationoflossadhocComponent, canActivate:[AuthGuard] },
{path: 'schedulec',component:  SchedulecComponent, canActivate:[AuthGuard]},
{path: 'certofcompliance',component:  CertificateofcomplianceComponent, canActivate:[AuthGuard]},
{path: 'certoflosssummary',component:  CertificationoflosssummaryComponent, canActivate:[AuthGuard]},
{path: 'excludingworkerscompensation', component: ExcludingworkerscompensationComponent, canActivate:[AuthGuard]},
{path: 'schedulea', component: ScheduleaComponent, canActivate:[AuthGuard]},
{path: 'noticeofdeductible', component: NoticeofdeductibleComponent, canActivate:[AuthGuard] },
{path: 'paymentdisbursement', component: PaymentdisbursementComponent, canActivate:[AuthGuard]},
{ path: 'index.html', redirectTo:'home'},
{ path: 'workerscompensation', component: WorkerscompensationComponent, canActivate:[AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
