<div class="rprtHeader non-printable">Certification of Loss Ad Hoc</div>
<div class="rprtHeaderSub non-printable">Selection Criteria</div>

<div class="filterBox non-printable">


    <div class="inputcontainer">
        <label for="username">Group Name</label>
        <select style="width: 165px !important; margin-right: 15px;" id="filter-group" class="selectLongInput"  [(ngModel)]="selectedGroup" (change)="onGroupChange()">
            <option *ngFor="let group of groupList" [value]="group.groupNumber">{{group.groupName}}</option>
        </select>
    </div>
    <div class="inputcontainer">
    <label for="username">Calendar Year</label>
        <select style="margin-right: 15px;" id="filter-calendar-year" class="selectShortInput" [(ngModel)]="selectedCalendarYear" (change)="onCalendarYearChange()">
            <option value="-1">--Select--</option>
            <option *ngFor="let calendarYear of calendarYearList" [value]="calendarYear">{{calendarYear}}</option>
        </select>
    </div>
    <div class="inputcontainer">
        <label for="EvaluationDate">Evaluation Date</label>
        <select style="width: 131px !important; margin-right: 15px;" id="filter-evaluation-date"  class="selectInput"  [(ngModel)]="selectedEvaluationDate" (change)="onEvaluationDateChange()">
            <option value="-1">--Select--</option>
            <option *ngFor="let evaluationDate of evaluationDateList" [value]="evaluationDate">{{evaluationDate}}</option>
        </select>
    </div>
    <div class="inputcontainer">
        <label for="username">ISO Receipt Date</label>
        <select style="margin-right: 15px;" id="filter-iso-recceipt-date"  class="selectInput"  [(ngModel)]="selectedIsoReceiptDate" (change)="onIsoReceiptDateChange()">
            <option value="-1">--Select--</option>
            <option *ngFor="let isoReceiptDate of isoReceiptDateList" [value]="isoReceiptDate">{{isoReceiptDate}}</option>
        </select>
    </div>
    <div class="inputcontainer">
      <label for="username">Format</label>
     <select style="width: 61px !important; margin-right: 15px;" [(ngModel)]="selectedReportType" class="selectShortInput1">
          <option *ngFor="let type of reportTypes" [value]="type.value">{{type.text}}</option>
      </select>
  </div>
 <button type="button" (click)="generateReport();" class="newButton rpt" >View Report</button>

</div>
<div id ="schedulea-report-id" class="tableWrapper" *ngIf="showGrid">
    <table width=100%>
        <tr>
            <td>
                <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
                    <tr class="lh-0_5">
                        <td>
                            &nbsp;</td>
                    </tr>
                    <tr>
                        <td style='padding-left:0pt' class=''>
                            <table border=0 cellspacing=0 cellpadding=0 width="100%">
                                <tr class="x-9 ta-r">
                                    <td>
                                        <span> Control No. </span>
                                        <span>{{certLossAdhocReport.controlNo}}</span>
                                    </td>
                                </tr>
                                <tr class="lh-1_1">
                                    <td>
                                        &nbsp;</td>
                                </tr>
                                <tr class="x-14 b ta-c lh-1_1">
                                    <td>
                                        <span>Terrorism Risk Insurance Program</span>
                                    </td>
                                </tr>
                                <tr class="x-12 b ta-c lh-1_1">
                                        <td>
                                        Certification of Loss</td>
                                </tr>
                                <tr class="x-12 b ta-c lh-1_1">
                                    <td>
                                        <span>{{certLossAdhocReport.insurerGroupName}}</span>
                                    </td>
                                </tr>
                                <tr class="lh-1_1">
                                    <td>
                                        &nbsp;</td>
                                </tr>
                                <tr class="lh-1_1">
                                    <td>
                                        &nbsp;</td>
                                </tr>
                                <tr class="x-9 lh-1_2">
                                    <td>
                                        <span>NAIC Insurer (or Group) Number or TIN (if no NAIC #): </span>
                                        <span>{{certLossAdhocReport.naicInsurer}}</span>
                                    </td>
                                </tr>
                                <tr class="x-9 lh-1_3">
                                    <td>
                                        <span>{{certLossAdhocReport.certType}} Certification </span>
                                    </td>
                                </tr>
                                <tr class="x-9 lh-1_3">
                                    <td>
                                        <span>Calendar Year:  </span>
                                        <span>{{certLossAdhocReport.calendarYear}}</span>
                                    </td>
                                </tr>
                                <tr class="x-9 lh-1_3">
                                    <td>
                                        <span>Data as of: </span>
                                        <span>{{certLossAdhocReport.dataAsOf}}</span>
                                    </td>
                                </tr>
                                <tr class="x-9 lh-1_3">
                                    <td>
                                        <span>ISO/PCS Catastrophe Code(s): </span>
                                        <span>{{certLossAdhocReport.catastropheCode}}</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            
                <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
                    <tr class="lh-1_3">
                        <td colspan=2>
                            &nbsp;</td>
                    </tr>
                    <tr class="x-9 b lh-1_3"><td style='padding-left:0pt' colspan=2>
                            <table border=0 cellspacing=0 cellpadding=0 width="100%">
                                <tr>
                                    <td>
                                        Calculation of Federal Share of Compensation Claimed</td>
                                </tr>
                                <tr>
                                    <td>
                                        (*amounts from Bordereau):</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            1. Total Cumulative Losses Paid and To Be Paid*</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.totalCumulativeLossesPaidandToBePaid}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            2. Plus Total Allocated Loss Adjustment Expenses Paid*</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.plusTotalAllocatedLossAdjustmentExpensesPaid}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            3. Less Punitive Damage Amounts Paid*</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.lessPunitiveDamageAmountsPaid}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            4. Subtotal Insured Losses Paid*</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.subtotalInsuredLossesPaid}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            5. Less Total Salvage and Subrogation Recovered*</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.lessTotalSalvageandSubrogationRecovered}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            6. Subtotal Adjusted Insured Losses Paid</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.subtotalAdjustedInsuredLossesPaid}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            7. Less Insurer Deductible (from Schedule A)</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.lessInsurerDeductible}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            8. Subtotal Excess of Deductible</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.subtotalExcessofDeductible}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            9. Gross Federal Share (% of subtotal Excess of Deductible)</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.grossFederalShare}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            10. Less Excess Insurer Recoveries (see below)</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.lessExcessInsurerRecoveries}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            11. Less Duplicate Federal Recoveries*</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.lessDuplicateFederalRecoveries}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            12. Total Net Federal Share of Compensation</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.totalNetFederalShareofCompensation}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            13. Less Prior Claimed Federal Share of Compensation</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.lessPriorClaimedFederalShareofCompensation}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                            14. Federal Share of Compensation due Insurer (due Treasury)</td>
                        <td class="ta-r">
                            {{certLossAdhocReport.federalShareofCompensationdueInsurer}}</td>
                    </tr>
                </table>
                <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
                    <tr>
                        <td colspan=2>
                            &nbsp;</td>
                    </tr>
                    <tr>
                        <td style='padding-left:0pt' colspan=2>
                            <table border=0 cellspacing=0 cellpadding=0 width="100%">
                                <tr class="x-9 b lh-1_1">
                                    <td>
                                        Calculation of Excess Insurer Recoveries (if required)</td>
                                </tr>
                            </table>
                            </td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                        15. Total Reinsurance Recoveries</td>
                        <td class="ta-r">
                        {{certLossAdhocReport.totalReinsuranceRecoveries}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                        16. Less Recoveries Repaid to Reinsurers</td>
                        <td class="ta-r">
                        {{certLossAdhocReport.lessRecoveriesRepaidtoReinsurers}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                        17. Subtotal Net Reinsurance Recoveries</td>
                        <td class="ta-r">
                        {{certLossAdhocReport.subtotalNetReinsuranceRecoveries}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                        18. Plus Gross Federal Share</td>
                        <td class="ta-r">
                        {{certLossAdhocReport.plusGrossFederalShare}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                        19. Subtotal Total Insurer Recoveries</td>
                        <td class="ta-r">
                        {{certLossAdhocReport.subtotalTotalInsurerRecoveries}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                        20. Less Adjusted Insured Losses Paid (line 6)</td>
                        <td class="ta-r">
                        {{certLossAdhocReport.lessAdjustedInsuredLossesPaid}}</td>
                    </tr>
                    <tr class="x-9 lh-1_4">
                        <td>
                        21. Total Excess Insurer Recoveries</td>
                        <td class="ta-r">
                        {{certLossAdhocReport.totalExcessInsurerRecoveries}}</td>
                    </tr>
                </table>
                <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
                    <tr>
                        <td>
                        &nbsp;</td>
                    </tr>
                    <tr>
                        <td style='padding-left:0pt'>
                            <table border=0 cellspacing=0 cellpadding=0 width="100%">
                                <tr class="x-9 lh-1_3">
                                    <td>
                                        Supporting Schedules Attached:</td>
                                </tr>
                                <tr class="x-9 lh-1_3">
                                    <td>
                                        <span>Schedule A  = </span>
                                        <span>{{certLossAdhocReport.scheduleA}}</span>
                                    </td>
                                </tr>
                                <tr class="x-9 lh-1_3">
                                    <td>
                                        Schedule B = {{certLossAdhocReport.scheduleB}}</td>
                                </tr>
                                <tr class="x-9 lh-1_3">
                                    <td>
                                        Schedule C = {{certLossAdhocReport.scheduleC}}</td>
                                </tr>
                                <tr class="x-9 lh-1_3">
                                    <td>
                                        <span>Submitted By:   </span>
                                        <span>{{certLossAdhocReport.submittedBy}}</span>
                                    </td>
                                </tr>
                                <tr class="x-9 lh-1_3">
                                    <td>
                                        <span>Title: </span>
                                        <span>{{certLossAdhocReport.title}}</span>
                                    </td>
                                </tr>
                                <tr class="x-9 lh-1_3">
                                    <td>
                                        <span>Date Signed: </span>
                                        <span>{{certLossAdhocReport.dateSigned}}</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        
    </table>
    
</div>