
<div id="htmlData">
<p align="center"><b><font size="6">Terrorism Risk Insurance Program</font></b></p>
<p align="center"><b><font size="5">Loss Bordereau (Schedule C) Ad Hoc</font></b></p>
<br/>
<br/>
<p align="center"><font face="Arial" size="4">Selection Criteria</font></p>

<table border="0" style="margin-left:305px" bordercolor="black" bgcolor="E6E6E6" cellpadding="0" cellspacing="0" width="33%">
<tr>
<td>
<br/>
<table border="0" cellpadding="5" cellspacing="0" width="100%" align="center">
<tr>
<td width="50%" align="right" nowrap>Group Name:</td>
<td width="50%" align="left">
<select  [(ngModel)]="selectedGroup">
<option *ngFor="let group of groupList" [value]="group.groupNumber">{{group.groupName}}</option>
</select>
</td>
</tr>
<tr>
<td width="50%" align="right" nowrap>Output Format:</td>
<td width="50%" align="left">
<select [(ngModel)]="selectedType">
<option *ngFor="let type of reportTypes" [value]="type.value">{{type.text}}</option>
</select>
</td>
</tr>
</table>
<br/>
</td>
</tr>
</table>

<p align="center">
<br/>
</p>
<p align="center"><input type="button" value="Submit" (click)="submit();" name="B1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
    <input type="reset" value="Reset" name="B2"></p>

</div>
