<div class="rprtHeader">
  Loss Bordereau for Residual Market Data (excluding Workers Compensation)
</div>
<div class="rprtHeaderSub">Selection Criteria</div>
<div class="filterBox">
  <div class="inputcontainer">
    <label for="calendar">Calendar Year</label>
    <select
      id="filter-calendar-year"
      class="selectShortInput"
      (change)="onCalendarYearChange()"
      [(ngModel)]="selectedCalendarYear"
    >
      <option
        *ngFor="let calendarYear of calendarYearList"
        [value]="calendarYear"
      >
        {{ calendarYear }}
      </option>
    </select>
  </div>
  <div class="inputcontainer">
    <label for="asofDate">As of Date</label>
    <input
      type="date"
      onkeydown="return false;"
      id="asofdateId"
      style="font-size: 12px; height: 27px"
      class="dateInput"
      (change)="onAsOfDateChange()"
      [ngModel]="todayDate | date: 'yyyy-MM-dd'"
      #asofDatePicker
    />
  </div>
  <div class="inputcontainer">
    <label for="catastrophe">Catastrophe Code</label>
    <select
      id="filter-catastrophe"
      class="selectShortInput"
      (change)="onCatastropheChange()"
      [(ngModel)]="selectedCatastrophe"
    >
      <option *ngFor="let catastrophe of catastropheList" [value]="catastrophe">
        {{ catastrophe }}
      </option>
    </select>
  </div>
  <div class="inputcontainer">
    <label for="output">Format</label>
    <select [(ngModel)]="selectedReportType" class="selectShortInput">
      <option *ngFor="let type of reportTypes" [value]="type.value">
        {{ type.text }}
      </option>
    </select>
  </div>

  <button type="button" (click)="generateReport()" class="newButton rpt">
    View Report
  </button>
</div>

<div *ngIf="showGrid" class="tableWrapper">
  <table
    class="header-box"
    border="0"
    cellspacing="0"
    cellpadding="0"
    width="100%"
  >
    <tr>
      <td class="x70">Terrorism Risk Insurance Program</td>
    </tr>
    <tr>
      <td class="x70">Information on Loss Amounts from Bordereau</td>
    </tr>
    <tr>
      <td class="x70">
        Residual Market Data (excluding Workers Compensation)
      </td>
    </tr>
    <tr>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td class="x70">
        Calendar Year: {{ selectedCalendarYear }} &nbsp;&nbsp; As of Date:
        {{ selectedAsofDate.nativeElement.value | date: "MM/dd/yyyy" }}
      </td>
    </tr>
  </table>
  <div id="ExcludeWC-report-id">
    <table
      class="w-135"
      cellpadding="1"
      style="
        padding-right: 10px;
        margin-left: 0pt;
        margin-right: 0pt;
        margin-top: 0pt;
        margin-bottom: 0pt;
      "
    >
      <tr>
        <td style="padding-left: 0pt" colspan="34"></td>
      </tr>
      <tr>
        <td style="vertical-align: bottom" class="x14">
          <u>Catastrophe Code</u>
        </td>
        <td style="vertical-align: bottom" class="x14">
          <u>State</u>
        </td>
        <td style="vertical-align: bottom" class="x14">
          <u>LOB</u>
        </td>
        <td style="vertical-align: bottom" class="x14 w-160p">
          <u>LOB Name</u>
        </td>
        <td style="vertical-align: bottom" class="x14">
          <u>Insurer Group No.</u>
        </td>
        <td style="vertical-align: bottom" class="x14">
          <u>Total Cumulative Loss Payments</u>
        </td>
        <td style="vertical-align: bottom" class="x14">
          <u>Total ALAE Paid</u>
        </td>
        <td style="vertical-align: bottom" class="x14 w-67p">
          <u>Ratio of ALAE to TRIA Loss</u>
        </td>
        <td style="vertical-align: bottom" class="x14">
          <u>Total Punitive Damages</u>
        </td>
        <td style="vertical-align: bottom" class="x14">
          <u>Total Salvage and Subrogation Recovered</u>
        </td>
        <td style="vertical-align: bottom" class="x14">
          <u>Net TRIA Insured Losses</u>
        </td>
        <td style="vertical-align: bottom" class="x14">
          <u>Duplicate Federal Compensation</u>
        </td>
        <td style="vertical-align: bottom" class="x14">
          <u>Case Reserves</u>
        </td>
      </tr>
      <ng-template
        ngFor
        let-state
        [ngForOf]="residualMarketDataReport.stateLst"
        ;
        let-stateIndex="index"
      >
        <ng-template
          ngFor
          let-lob
          [ngForOf]="state.lobLst"
          ;
          let-lobIndex="index"
        >
          <tr
            *ngIf="!(stateIndex == 0 && lobIndex == 0)"
            style="line-height: 1"
          >
            <td>&nbsp;</td>
          </tr>
          <ng-template
            ngFor
            let-item
            [ngForOf]="lob.rmDetails"
            ;
            let-itemIndex="index"
          >
            <tr class="grid-data-row row_{{ item.reportRowNumber % 2 }}">
              <td>
                {{ item.catCode }}
              </td>
              <td>
                {{ item.stateCode }}
              </td>
              <td>
                {{ item.lobCode }}
              </td>
              <td>
                {{ item.lobName }}
              </td>
              <td>
                {{ item.insurerGroupNumber }}
              </td>
              <td class="align-right">${{ item.totalCumLossPayments }}</td>
              <td class="align-right">${{ item.totalALAEPaid }}</td>
              <td class="align-right">
                {{ item.ratioOfALAEtoTRIALoss }}
              </td>
              <td class="align-right">${{ item.totalPunitiveDamages }}</td>
              <td class="align-right">
                ${{ item.totalSalvageandSubrogationRecovered }}
              </td>
              <td class="align-right">${{ item.netTRIAInsuredLosses }}</td>
              <td class="align-right">
                ${{ item.duplicateFederalCompensation }}
              </td>
              <td class="align-right">${{ item.caseReserves }}</td>
            </tr>
          </ng-template>
          <tr style="line-height: 1">
            <td>&nbsp;</td>
          </tr>
          <tr class="lob-total" style="line-height: 1.2">
            <td colspan="5" class="x27">LOB Total {{ lob.lobCode }}</td>
            <td class="x27">${{ lob.lobTotalofTotCumLossPayments }}</td>
            <td class="x27">${{ lob.lobTotalALAEPaid }}</td>
            <td class="x27">
              {{ lob.lobTotalRatioOfALAEtoTRIALoss }}
            </td>
            <td class="x27">${{ lob.lobTotalPunitiveDamages }}</td>
            <td class="x27">
              ${{ lob.lobTotalSalvageandSubrogationRecovered }}
            </td>
            <td class="x27">${{ lob.lobTotalNetTRIAInsuredLosses }}</td>
            <td class="x27">${{ lob.lobTotalDuplicateFederalCompensation }}</td>
            <td class="x27">${{ lob.lobTotalCaseReserves }}</td>

          </tr>
        </ng-template>
        <tr style="line-height: 1.2">
          <td colspan="5" class="x27">State Total {{ state.stateCode }}</td>
          <td class="x27">${{ state.stateTotalofTotCumLossPayments }}</td>
          <td class="x27">${{ state.stateTotalALAEPaid }}</td>
          <td class="x27">
            {{ state.stateTotalRatioOfALAEtoTRIALoss }}
          </td>
          <td class="x27">${{ state.stateTotalPunitiveDamages }}</td>
          <td class="x27">
            ${{ state.stateTotalSalvageandSubrogationRecovered }}
          </td>
          <td class="x27">${{ state.stateTotalNetTRIAInsuredLosses }}</td>
          <td class="x27">
            ${{ state.stateTotalDuplicateFederalCompensation }}
          </td>
          <td class="x27">${{ state.stateTotalCaseReserves }}</td>
        </tr>
      </ng-template>
      <tr
        *ngIf="residualMarketDataReport.stateLst.length > 0"
        style="line-height: 1.1"
      >
        <td colspan="5" class="x27">
          Catastrophe Total {{ residualMarketDataReport.catCode }}
        </td>
        <td class="x27">
          ${{ residualMarketDataReport.catTotalofTotCumLossPayments }}
        </td>
        <td class="x27">${{ residualMarketDataReport.catTotalALAEPaid }}</td>
        <td class="x27">
          {{ residualMarketDataReport.catTotalRatioOfALAEtoTRIALoss }}
        </td>
        <td class="x27">${{ residualMarketDataReport.catTotalPunitiveDamages }}</td>
        <td class="x27">
          ${{ residualMarketDataReport.catTotalSalvageandSubrogationRecovered }}
        </td>
        <td class="x27">
          ${{ residualMarketDataReport.catTotalNetTRIAInsuredLosses }}
        </td>
        <td class="x27">
          ${{ residualMarketDataReport.catTotalDuplicateFederalCompensation }}
        </td>

        <td class="x27">${{ residualMarketDataReport.catTotalCaseReserves }}</td>
      </tr>
    </table>
  </div>
</div>
