import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-residual-market-data',
  templateUrl: './residual-market-data.component.html',
  styleUrls: ['./residual-market-data.component.scss'],
})
export class ResidualMarketDataComponent implements OnInit {
  calendarYear: any;
  calendarYearList: any = [];
  selectedCalendarYear: any;
  catastropheList: any = [];
  selectedCatastrophe: any;
  todayDate: any;
  selectedReportType: string;
  reportTypes: any = [
    { value: 'html', text: 'Html' },
    { value: 'excel', text: 'Excel' },
  ];
  residualMarketDataReport: any;
  showGrid: boolean = false;
  @ViewChild('asofDatePicker') selectedAsofDate: ElementRef;

  constructor(private reportSvc: ReportService) {}

  ngOnInit(): void {
    this.getCalendarYears();
  }
  getCalendarYears() {
    this.todayDate = new Date();
    this.selectedReportType = 'html';
    this.reportSvc.getResidualMarketDataReportCalendarYears().subscribe(
      (res) => {
        this.calendarYearList = res;
        this.selectedCalendarYear = this.calendarYearList[0];
        this.getCatastrophe();
      },
      () => {}
    );
  }
  getCatastrophe() {
    this.todayDate = new Date();
    this.selectedReportType = 'html';
    this.reportSvc.getResidualMarketDataReportCatastrophe().subscribe(
      (res) => {
        this.catastropheList = res;
        this.selectedCatastrophe = this.catastropheList[0];
      },
      () => {}
    );
  }

  onCalendarYearChange() {
    this.showGrid = false;
  }

  onAsOfDateChange() {
    this.showGrid = false;
  }
  onCatastropheChange() {
    this.showGrid = false;
  }
  generateReport() {
    if (this.selectedReportType === 'html') {
      this.getResidualMarketDataReport();
    } else if (this.selectedReportType === 'excel') {
      this.getFile();
    }
  }

  getResidualMarketDataReport() {
    this.reportSvc
      .getResidualMarketDataReport(
        this.selectedCalendarYear,
        this.selectedAsofDate.nativeElement.value,
        this.selectedCatastrophe
      )
      .subscribe(
        (res) => {
          this.residualMarketDataReport = res;
          this.showGrid = true;
        },
        () => {}
      );
  }

  getFile() {
    this.reportSvc
      .getResidualMarketDataReportFile(
        this.selectedCalendarYear,
        this.selectedAsofDate.nativeElement.value,
        this.selectedCatastrophe
      )
      .subscribe(
        (respData) => {
          this.downLoadFile(
            respData,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
        },
        () => {}
      );
  }

  downLoadFile(data: any, type: string) {
    let downloadLink = document.createElement('a');
    let filename: string = 'Residual Market Data Excluding Workers Comp.xlsx';
    downloadLink.href = window.URL.createObjectURL(
      new Blob([data], { type: 'blob' })
    );
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
