import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../appconfig.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  getCookie(name: string): string {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(';')
        .map((c) => c.trim())
        .filter((cookie) => {
          return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map((cookie) => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null
    );
  }

  should_intercept(url): boolean {
    if (url.startsWith('assets/json')) {
      // to prevent asset fetch from intercepted
      return false;
    }
    if (url.startsWith(AppConfig.settings.apiUrl)) {
      //intercept all request to api base
      return true;
    }
    return false;
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const header = this.getCookie('ticket');
    if (this.should_intercept(req.url) && header != null) {
      const headers = req.headers.set('ticket', header);
      req = req.clone({ headers });
    }

    return next.handle(req);
  }
}
