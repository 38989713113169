

<div class="rprtHeader non-printable">Schedule B - Certification of Compliance Ad Hoc</div>
<div class="rprtHeaderSub non-printable">Selection Criteria</div>

<div class="filterBox non-printable">


        <div class="inputcontainer">
            <label for="username">Group Name</label>
            <select id="filter-group" class="selectLongInput"  [(ngModel)]="selectedGroup" (change)="onGroupChange()" style="width: 135px;">
                <option *ngFor="let group of groupList" [value]="group.groupNumber">{{group.groupName}}</option>
            </select>
        </div>
        <div class="inputcontainer">
        <label for="username">Calendar Year</label>
            <select id="filter-calendar-year" class="selectShortInput" [(ngModel)]="selectedCalendarYear" (change)="onCalendarYearChange()">
                <option value="-1">--Select--</option>
                <option *ngFor="let calendarYear of calendarYearList" [value]="calendarYear">{{calendarYear}}</option>
            </select>
        </div>
        <div class="inputcontainer">
            <label for="username">ISO Receipt Date</label>
            <select id="filter-iso-recceipt-date"  class="selectInput"  [(ngModel)]="selectedIsoReceiptDate" (change)="onIsoReceiptDateChange()">
                <option value="-1">--Select--</option>
                <option *ngFor="let isoReceiptDateItem of isoReceiptDateList" [value]="isoReceiptDateItem.isoReceiptDate">{{isoReceiptDateItem.isoReceiptDate}}</option>
            </select>
        </div>

        <div class="inputcontainer">
          <label for="username">Format</label>
         <select [(ngModel)]="selectedReportType" class="selectShortInput1">
              <option *ngFor="let type of reportTypes" [value]="type.value">{{type.text}}</option>
          </select>
         </div>

     <button type="button" (click)="generateReport();" class="newButton rpt" >View Report</button>
</div>




<div class="tableWrapper" style="height:400px;"  *ngIf="showGrid">

    <table id ="certificateofcompliance-report-id" #certofcompliance cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
        <tr><td>&nbsp;</td></tr>
        <tr>
            <td style='padding-left:0pt'>
                <table border=0 cellspacing=0 cellpadding=0 width="100%">
                    <tr>
                        <td align=RIGHT>
                            <span class='x3'>Control No. </span>
                            <span class='x5'>{{certOfComplianceReport.controlNumber}}</span>
                            <span class='x4'> </span>
                        </td>
                    </tr>
                    <tr><td class='x6'>&nbsp;</td></tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style='padding-left:0pt'>
                <table border=0 cellspacing=0 cellpadding=0 width="100%">
                    <tr class="main-header">
                        <td class='x8'>Schedule B</td>
                    </tr>
                    <tr class="main-header">
                        <td class='x10'>TERRORISM RISK INSURANCE PROGRAM</td>
                    </tr>
                    <tr><td class='x12'>&nbsp;</td></tr>
                    <tr class="main-header">
                        <td class='x15'>CERTIFICATION OF COMPLIANCE WITH SECTION 103(b)</td>
                    </tr>
                    <tr class="main-header">
                        <td class='x17'>OF TERRORISM RISK INSURANCE ACT (TRIA)</td>
                    </tr>
                    <tr><td class='x20'>&nbsp;</td></tr>
                    <tr>
                        <td>
                            Insurer or Group Name: <span class='x22'>{{certOfComplianceReport.groupName}}</span>
                        </td>
                    </tr>
                    <tr><td class='x23'>&nbsp;</td></tr>
                    <tr>
                        <td>
                            NAIC Insurer (or Group) Number or TIN (if no NAIC #): <span class='x26'>{{certOfComplianceReport.groupNumber}}</span>
                        </td>
                    </tr>
                    <tr><td class='x28'>&nbsp;</td></tr>
                    <tr>
                        <td>
                            Calendar Year: <span class='x30'>{{certOfComplianceReport.calendarYear}}</span>
                        </td>
                    </tr>
                    <tr><td>&nbsp;</td></tr>
                    <tr>
                        <td>
                            Name: <span class='x33'>{{certOfComplianceReport.firstLastName}}</span>
                        </td>
                    </tr>
                    <tr><td>&nbsp;</td></tr>
                    <tr>
                        <td>
                            Officer Title: <span class='x36'>{{certOfComplianceReport.officerTitle}}</span>
                            <span class='x35'> </span>
                        </td>
                    </tr>
                    <tr><td class='x37'>&nbsp;</td></tr>
                    <tr>
                        <td>
                            <span class='x38'> </span>
                            Date Signed: <span class='x39'>{{certOfComplianceReport.dateSigned | date : 'yyyy/MM/dd HH:mm:ss'}}</span>
                        </td>
                    </tr>
                    <tr><td class='x40'>&nbsp;</td></tr>
                </table>
            </td>
        </tr>
        <tr><td>&nbsp;</td></tr>
        <!-- <tr><td>&nbsp;</td></tr>
        <tr><td>&nbsp;</td></tr>
        <tr><td>&nbsp;</td></tr> -->
        <!-- <tr><td>&nbsp;</td></tr>
        <tr><td>&nbsp;</td></tr>
        <tr><td>&nbsp;</td></tr>
        <tr><td>&nbsp;</td></tr>
        <tr><td>&nbsp;</td></tr>
        <tr><td>&nbsp;</td></tr> -->
    </table>
</div>
