import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReportService } from '../../report.service';

@Component({
  selector: 'app-certificationoflosssummary',
  templateUrl: './certificationoflosssummary.component.html',
  styleUrls: ['./certificationoflosssummary.component.scss'],
})
export class CertificationoflosssummaryComponent implements OnInit {
  calendarYear: any;
  calendarYearList: any = [];
  selectedCalendarYear: any;
  todayDate: any;
  selectedReportType: string;
  reportTypes: any = [
    { value: 'html', text: 'Html' },
    { value: 'excel', text: 'Excel' },
  ];
  lossSummaryReport: any;
  losses: any = [];
  showGrid: boolean = false;
  @ViewChild('asofDatePicker') selectedAsofDate: ElementRef;
  constructor(private reportSvc: ReportService) {}

  ngOnInit(): void {
    this.getCalendarYears();
  }

  onCalendarYearChange() {
    this.showGrid = false;
  }

  onAsOfDateChange() {
    this.showGrid = false;
  }

  getCalendarYears() {
    this.todayDate = new Date();
    this.selectedReportType = 'html';
    this.reportSvc.getLossSummaryCalendarYears().subscribe(
      (res) => {
        this.calendarYearList = res;
        this.selectedCalendarYear = this.calendarYearList[0].calendarYear;
      },
      () => {}
    );
  }
  generateReport() {
    if (this.selectedReportType === 'html') {
      this.getLossSummaryReport();
    } else if (this.selectedReportType === 'excel') {
      this.getFile();
    }
  }
  getLossSummaryReport() {
    this.reportSvc
      .getLossSummaryReport(
        this.selectedCalendarYear,
        this.selectedAsofDate.nativeElement.value
      )
      .subscribe(
        (res) => {
          this.lossSummaryReport = res;
          this.losses = this.lossSummaryReport.losses;
          this.showGrid = true;
        },
        () => {}
      );
  }

  getFile() {
    this.reportSvc
      .getLossSummaryFile(
        this.selectedCalendarYear,
        this.selectedAsofDate.nativeElement.value
      )
      .subscribe(
        (respData) => {
          this.downLoadFile(
            respData,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
        },
        () => {}
      );
  }
  downLoadFile(data: any, type: string) {
    let downloadLink = document.createElement('a');
    let filename: string = 'Summary Based on Certification of Loss.xlsx';
    downloadLink.href = window.URL.createObjectURL(
      new Blob([data], { type: 'blob' })
    );
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
