<div class="lib-header-container" style="min-width: 100%; width: auto;">
  <div class="lib-top-header-container" style="min-width: 100%; width: auto;">
    <div class="lib-header">
      <div class="lib-head-icon">
        <a class="lib-head-logo">
          <div class="lib-head-home-icon"></div>
        </a>
      </div>
 
   
      <div (clickOutside)="showingProfile = false" id="profileMenu-bar">
        <div
          class="lib-head-profile-menu"
          (click)="showingProfile = !showingProfile"
          [ngClass]="showingProfile && 'menu-active'"
        > 
          <span><i class="material-icons">person</i
            ><i class="material-icons"> arrow_drop_down </i></span
          >
        </div>
        <div
          [ngClass]="
            showingProfile ? 'show-profile lib-profile-dd' : 'lib-profile-dd'
          "
        >
          <div>
            <div>
              <li (click)="loggedOut.emit($event)">
                <a> Sign Out </a>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div style="float:right; margin: 21px 3px; font-size: 12px; font-family:Arial;color: #004b87;"><span> Welcome {{userName}}</span></div>
    </div>

   </div>
  <div class="lib-product-header-container" style="min-width: 100%; width: auto;">
    <div class="lib-product-header">
      <div class="lib-produect-head-title">
        <span>Terrorism Risk Insurance Program</span>
      </div>
      <div class="home-logo" >
        <a [routerLink]="['/home']" style="color:white;">
        <span >
          <i class="material-icons">
          home
        </i> </span></a>
      </div>
    </div>
  </div>
</div>
