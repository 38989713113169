import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ReportService } from '../report.service';
import { Observable } from 'rxjs';
import { TripAlertService } from '../trip-alert.service';

@Component({
  selector: 'app-schedulea',
  templateUrl: './schedulea.component.html',
  styleUrls: ['./schedulea.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ScheduleaComponent implements OnInit {
  selectedGroup: any;
  selectedCalendarYear: any;
  selectedIsoReceiptDate: any;
  scheduleAReport: any;
  groupList: any = [];
  filterList: any = [];
  @ViewChild('reportElement') reportElement: ElementRef;




  groupNumber: string;
  calendarYear: any;
  isoReceiptDate: any;
  calendarYearList: any = [];
  isoReceiptDateList: any = [];
  showGrid: boolean = false;
  filteredGroups: Observable<string[]>;
  reportTypes:any =[{value:'html', text:'Html'}, {value:'pdf' , text: 'PDF'}];
  selectedReportType:string = 'html';

  constructor(
    private reportSvc: ReportService,
    private alert:TripAlertService,
    private ChangeRef: ChangeDetectorRef
  ) {}


  ngOnInit(): void {
    this.getGroups();

  }

  getGroups() {
    this.selectedCalendarYear = '-1';
    this.selectedIsoReceiptDate = '-1';
    this.showGrid = false;
    this.reportSvc.getScheduleAGroups().subscribe(
      (res) => {
        if (res) {
          this.groupList = res;
          this.selectedGroup = this.groupList[0].groupNumber;
          this.getFilterList();
        }
      },
      () => {}
    );
  }

  onGroupChange() {
    this.selectedCalendarYear = '-1';
    this.isoReceiptDateList = [];
    this.showGrid = false;
    this.getFilterList();
  }
  onCalendarYearChange() {
    this.selectedIsoReceiptDate = '-1';
    this.showGrid = false;
    this.isoReceiptDateList = this.filterList.filter(
      (x) => x.calendarYear == this.selectedCalendarYear
    );
  }
  onIsoReceiptDateChange() {
    this.showGrid = false;
  }

  getFilterList() {
    this.reportSvc.getScheduleAFilter(this.selectedGroup).subscribe(
      (res) => {
        if (res) {
          this.filterList = res;
          this.calendarYearList = this.filterList
            .map((item) => item.calendarYear)
            .filter((value, index, self) => self.indexOf(value) === index);
        }
      },
      () => {}
    );
  }

  getScheduleaReport(aspdf=false) {
    this.reportSvc
      .getScheduleAReport(
        this.selectedGroup,
        this.selectedCalendarYear,
        this.selectedIsoReceiptDate
      )
      .subscribe(
        (res) => {
          if (res) {
            this.scheduleAReport = res;
            this.showGrid = true;
            if(aspdf){
              this.ChangeRef.detectChanges();
              this.generatePdf();
              // this.showGrid = false;
            }
          }
        },
        () => {}
      );
  }

  generateReport() {
    if (this.selectedCalendarYear === '-1') {
      this.alert.alert('Please select Calendar Year');
      return;
    }

    if (this.selectedIsoReceiptDate === '-1') {
      this.alert.alert('Please select ISO Receipt Date');
      return;
    }
    var aspdf = false;
    if(this.selectedReportType === 'pdf'){
      aspdf=true
    }
    this.getScheduleaReport(aspdf);
  }



  generatePdf(){
    var tit = document.title;
    document.title = 'Schedule A Ad Hoc';
    window.print();
    document.title = tit ;   // this.showGrid=false;
  }
}
