<div class="rprtHeader non-printable">Notice of Deductible Erosion Ad Hoc</div>
<div class="rprtHeaderSub non-printable">Selection Criteria</div>

<div class="filterBox non-printable">


    <div class="inputcontainer">
        <label for="username">Group Name</label>
        <select id="filter-group" class="selectLongInput"  [(ngModel)]="selectedGroup" (change)="onGroupChange()">
            <option *ngFor="let group of groupList" [value]="group.groupNumber">{{group.groupName}}</option>
        </select>
    </div>
    <div class="inputcontainer">
    <label for="username">Calendar Year</label>
        <select id="filter-calendar-year" class="selectShortInput" [(ngModel)]="selectedCalendarYear" (change)="onCalendarYearChange()">
            <option value="-1">--Select--</option>
            <option *ngFor="let calendarYear of calendarYearList" [value]="calendarYear">{{calendarYear}}</option>
        </select>
    </div>
    <div class="inputcontainer">
        <label for="username">ISO Receipt Date</label>
        <select id="filter-iso-recceipt-date"  class="selectInput"  [(ngModel)]="selectedIsoReceiptDate" (change)="onIsoReceiptDateChange()">
            <option value="-1">--Select--</option>
            <option *ngFor="let isoReceiptDate of isoReceiptDateList" [value]="isoReceiptDate">{{isoReceiptDate}}</option>
        </select>
    </div>
    <div class="inputcontainer">
      <label for="username">Format</label>
     <select [(ngModel)]="selectedReportType" class="selectShortInput1">
          <option *ngFor="let type of reportTypes" [value]="type.value">{{type.text}}</option>
      </select>
    </div>
 <button type="button" (click)="generateReport();" class="newButton rpt" >View Report</button>

</div>

<!-- <button type="button" class="newButton" (click)="openPDF()">Download PDF</button> -->
<div id ="schedulea-report-id" class="tableWrapper" *ngIf="showGrid">
    <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
        <!-- <tr>
        <td colspan=2>
        &nbsp;</td>
        </tr> -->
        <tr class="lh-1"><td style='padding-left:0pt' colspan=2>
                <table border=0 cellspacing=0 cellpadding=0 width="100%">
                    <tr class="x-10 ta-r">
                        <td>
                            <span>Control   No: </span>
                            <span>{{noticeofDductibleReport.controlNo}}</span>
                        </td>
                    </tr>
                </table></td></tr>
        <tr><td style='padding-left:0pt' colspan=2>
                <table border=0 cellspacing=0 cellpadding=0 width="100%">
                    <tr class="x-12 b ta-c lh-1">
                        <td>
                            Terrorism Risk Insurance Program</td>
                    </tr>
                    <tr class="x-12 b ta-c lh-1_1">
                        <td>
                            Notice of Deductible Erosion</td>
                    </tr>
                    <tr class="lh-1_1">
                        <td>
                            &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>Insurer or Insurer Group Name: </span>
                            <span class='b'>{{noticeofDductibleReport.insurerGroupName}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1">
                        <td>
                        &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1">
                        <td>
                            <span>NAIC Insurer (or Group) Number (or TIN if no NAIC #): </span>
                            <span class='b'>{{noticeofDductibleReport.naiCorGrouporTinNumber}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1_1">
                        <td>
                        &nbsp;</td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr class="x-9 va-b lh-1_1">
            <td>
                <u>Affiliated Insurer Name</u></td>
            <td>
                <u>NAIC # (or TIN if no NAIC #)</u></td>
        </tr>
        <tr class="lh-1_2" *ngFor="let insurer of noticeofDductibleReport.affiliatedInsurers; let i = index">
            <td [ngClass]="{ 'x53_0' : i % 2==0, 'x53_1' : i % 2 !=0 }">
                {{insurer.affiliatedInsurerName}}</td>
            <td [ngClass]="{ 'x52_0' : i % 2==0, 'x52_1' : i % 2 !=0 }">
                {{insurer.affiliatedNumber}}</td>
        </tr>
    </table>

    <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
        <tr class="lh-1_1">
            <td>
            &nbsp;</td>
        </tr>
        <tr><td style='padding-left:0pt'>
                <table border=0 cellspacing=0 cellpadding=0 width="100%">
                    <tr class="x-9 lh-1_2">
                        <td>
                            <span>Calendar Year: </span>
                            <span class='b'>{{noticeofDductibleReport.calendarYear}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1_1">
                        <td>
                        &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>ISO/PCS Catastrophe Code(s): </span>
                            <span class='b'>{{noticeofDductibleReport.catCode}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1">
                        <td>
                        &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1">
                        <td>
                            <span>Occurrence Date(s) of certified act(s) of terrorism: </span>
                            <span class='b'>{{noticeofDductibleReport.occurrenceDate}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1">
                        <td>
                        &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1">
                        <td>
                            <span>Estimated incurred aggregate insured losses from Program Trigger event(s):</span>
                            <span class='b'>{{noticeofDductibleReport.estimatedIncurredAggregate}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1">
                        <td>
                        &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1">
                        <td>
                            <span>Estimated insurer deductible for Program Year: </span>
                            <span class='b'>{{noticeofDductibleReport.estimatedInsurerDeductible}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1">
                        <td>
                        &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1">
                        <td>
                            <span>Estimated Federal share of compensation: </span>
                            <span class='b'>{{noticeofDductibleReport.estimatedFederalShare}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1">
                        <td>
                        &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1">
                        <td>
                            <span>Contact's Name: </span>
                            <span class='b'>{{noticeofDductibleReport.contactsName}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1_2">
                        <td>
                            &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1">
                        <td>
                            <span>Contact's Title: </span>
                            <span class='b'>{{noticeofDductibleReport.contactsTitle}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1_2">
                        <td>
                            &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1">
                        <td>
                            <span>Contact's Company: </span>
                            <span class='b'>{{noticeofDductibleReport.contactsCompany}}</span>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

    <table cellpadding=1 style='margin-left:0pt;margin-right:0pt;margin-top:0pt;margin-bottom:0pt'>
        <tr class="lh-1_1">
            <td>
            &nbsp;</td>
        </tr>
        <tr><td style='padding-left:0pt'>
                <table border=0 cellspacing=0 cellpadding=0 width="100%">
                    <tr class="x-9 lh-1_1">
                        <td>
                        Mailing Address:</td>
                    </tr>
                    <tr class="x-9 b lh-1_2">
                        <td>
                            {{noticeofDductibleReport.addr1}}</td>
                    </tr>
                    <tr class="x-9 b lh-1_2">
                        <td>
                            {{noticeofDductibleReport.addr2}}
                        </td>
                    </tr>
                    <tr class="x-9 b lh-1_2">
                        <td>
                            <span>{{noticeofDductibleReport.city}} </span>
                            <span>{{noticeofDductibleReport.state}} </span>
                            <span>{{noticeofDductibleReport.zipCode}} </span>
                            <span>{{noticeofDductibleReport.country}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1">
                        <td>
                        &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>Telephone Number(s): </span>
                            <span class='b'>{{noticeofDductibleReport.telephoneNumber}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1_1">
                        <td>
                            &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>Fax Number(s): </span>
                            <span class='b'>{{noticeofDductibleReport.faxNumber}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1">
                        <td>
                            &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>E-mail Address: </span>
                            <span class='b'>{{noticeofDductibleReport.email}}</span>
                        </td>
                    </tr>
                    <tr class="lh-0_8">
                        <td>
                            &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-0_9">
                        <td>
                            <span>Optional Schedule A (Declaration of Direct Earned Premium and Insurer Deductible) attached (Y or N)?  </span>
                            <span class='b'>{{noticeofDductibleReport.optionalScheduleA}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1_1">
                        <td>
                            &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            Submitted by:</td>
                    </tr>
                    <tr class="lh-1_1">
                        <td>
                            &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>Name: </span>
                            <span class='b'>{{noticeofDductibleReport.submittedByName}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1_1">
                        <td>
                            &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>Title: </span>
                            <span class='b'>{{noticeofDductibleReport.submittedByTitle}}</span>
                        </td>
                    </tr>
                    <tr class="lh-1_1">
                        <td>
                            &nbsp;</td>
                    </tr>
                    <tr class="x-9 lh-1_1">
                        <td>
                            <span>Date: </span>
                            <span class='b'>{{noticeofDductibleReport.submittedByDate}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            &nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            &nbsp;</td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</div>
